import {
  CloseButton,
  Popover,
  PopoverButton,
  PopoverPanel,
} from '@headlessui/react';
import {
  FunnelIcon,
  PencilSquareIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useRef } from 'react';
import CardHeader from '../../commons/CardHeader';
import IconButton from '../../commons/inputs/IconButton';

interface TableLayoutProps {
  title?: string | React.ReactNode;
  tooltip?: string;
  children?: React.ReactNode;
  extraActions?: React.ReactNode;
  actions?: {
    edit?: () => void;
    delete?: () => void;
    close?: () => void;
  };
  filters?: {
    name: string;
    key: string;
    handler: (value: any) => void;
  }[];
  icon?: React.ReactNode;
}

export default function TableLayout(props: TableLayoutProps) {
  const {
    title = '',
    tooltip,
    children,
    actions,
    extraActions,
    filters,
  } = props;

  const filterPanelRef = useRef<HTMLDivElement>(null);
  const filterPanelTop =
    filterPanelRef.current?.getBoundingClientRect()?.top || undefined;
  return (
    <div>
      <div className="px-4 py-3 flex justify-between items-center space-x-2 tableLayoutTitle">
        {props?.icon}
        {typeof title === 'string' ? (
          <CardHeader title={title} tooltip={tooltip}></CardHeader>
        ) : (
          <CardHeader>{title}</CardHeader>
        )}
        {actions && (
          <div
            className={clsx(
              'flex items-center justify-between',
              'border border-base-900 rounded-lg bg-base-1000'
            )}
          >
            <span className="flex items-center px-3 py-2 self-stretch text-md-bold border-r border-base-900">
              Actions
            </span>
            {extraActions}
            <div
              className={clsx(
                'flex px-3 py-2 space-x-2',
                extraActions && 'border-l border-base-900'
              )}
            >
              {actions?.edit && (
                <IconButton>
                  <PencilSquareIcon className="size-3" />
                </IconButton>
              )}

              {actions?.close && (
                <IconButton>
                  <XMarkIcon className="size-3" />
                </IconButton>
              )}

              {actions?.delete && (
                <IconButton>
                  <TrashIcon className="size-3" />
                </IconButton>
              )}
            </div>
          </div>
        )}
        {filters && (
          <Popover className="relative flex items-center">
            <PopoverButton>
              <FunnelIcon className="size-6" />
            </PopoverButton>
            <PopoverPanel
              ref={filterPanelRef}
              anchor={{
                to: 'bottom end',
                offset: 17,
                gap: -41,
              }}
              className={clsx(
                'shadow-lg shadow-black/50 rounded-lg border border-base-1000'
              )}
              style={{
                top: filterPanelTop && `${Math.round(filterPanelTop) - 20}px`,
              }}
            >
              <div className="bg-block-fill p-4 min-w-[180px]">
                <span className="flex justify-between">
                  <p>Filter Table By:</p>
                  <CloseButton>
                    <FunnelIcon className="size-6" />
                  </CloseButton>
                </span>
                <ul className="mt-2">
                  {filters.map((filter) => (
                    <li
                      key={filter.key}
                      className={clsx(
                        'pl-2 border-b border-base-1000 py-2',
                        'last:border-0 last:pb-0'
                      )}
                    >
                      {filter.name}
                    </li>
                  ))}
                </ul>
              </div>
            </PopoverPanel>
          </Popover>
        )}
        {!actions && extraActions && <div>{extraActions}</div>}
      </div>
      {children}
    </div>
  );
}
