import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import IconButton from '../inputs/IconButton';
import FileDownloadIcon from '../../../assets/icons/FileDownloadIcon';
import clsx from 'clsx';
import Papa from 'papaparse';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

type DownloadButtonProps = {
  data: any[];
};

export default function TableDownloadButton({ data }: DownloadButtonProps) {
  // Handle download
  const downloadCSV = () => {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'inbound-grain.csv';
    link.click();
  };

  // Handle download PDF
  const downloadPDF = () => {
    const csv = Papa.unparse(data);
    const doc = new jsPDF();
    const rows = csv.split('\n').map((row) => row.split(','));
    autoTable(doc, {
      head: [rows[0]],
      body: rows.slice(1),
      styles: {
        cellWidth: 'wrap',
      },
    });
    doc.save('inbound-grain.pdf');
  };

  return (
    <Menu>
      <MenuButton>
        <IconButton className="w-8" color="primary">
          <FileDownloadIcon fill="white" />
        </IconButton>
      </MenuButton>
      <Transition
        enter="transition ease-out duration-75"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <MenuItems
          unmount={false}
          anchor="bottom end"
          className={clsx(
            'menuItems origin-top-right rounded-xl mt-3',
            'bg-block-fill text-base-500 shadow-xl whitespace-nowrap p-4 flex flex-col',
            'space-y-4'
          )}
        >
          <MenuItem>
            <button
              className="bg-transparent text-base-500 flex hover:!text-primary"
              onClick={downloadPDF}
            >
              <p>Download PDF</p>
            </button>
          </MenuItem>
          <MenuItem>
            <button
              className="bg-transparent text-base-500 flex hover:!text-primary"
              onClick={downloadCSV}
            >
              <p>Download CSV</p>
            </button>
          </MenuItem>
          <MenuItem disabled>
            <button className="bg-transparent text-base-500 flex hover:!text-primary">
              <p>Send to Email</p>
            </button>
          </MenuItem>
        </MenuItems>
      </Transition>
    </Menu>
  );
}
