import { useParams } from 'react-router-dom';

function useTypeParams() {
  const { type: typeParam = '' } = useParams();

  // Transform the typeParam based on your condition
  const transformedTypeParam = typeParam === 'ndvi_pct' ? 'ndvi_deviation' : typeParam;

  return transformedTypeParam;
}

export default useTypeParams;
