import { useEffect, useMemo, useState } from 'react';
import { Field, Label } from '@headlessui/react';
import { AttributeOptions } from '../../../constants';
import { FieldPrescriptionRes, PrescriptionMapLegend } from '../../../types';
import Checkbox from '../../commons/Checkbox';
import SliderInput from '../../commons/inputs/SliderInput';
import NumberDisplay from '../../commons/NumberDisplay';
import PrescriptionMap from './PrescriptionMap';
import clsx from 'clsx';

interface PrescriptionMapContainerProps {
  prescriptions: FieldPrescriptionRes[];
  crop: string;
  field: string;
}

export default function PrescriptionMapContainer({
  prescriptions,
  crop,
  field,
}: PrescriptionMapContainerProps) {
  const [selectedZoneNames, setSelectedZoneNames] = useState<string[]>([]);
  const [selectedAttribute, setSelectedAttribute] =
    useState<string>('predicted_yield');
  const [selectAll, setSelectAll] = useState(true);
  const [legendData, setLegendData] = useState<PrescriptionMapLegend>();
  const [scale, setScale] = useState(10);

  useEffect(() => {
    if (selectedZoneNames.length === 0) setSelectAll(true);
  }, [selectedZoneNames.length]);

  const handleSelectZones = (zoneId: string) => {
    setSelectAll(false);
    setSelectedZoneNames(
      selectedZoneNames.length === 0
        ? [zoneId]
        : selectedZoneNames.includes(zoneId)
          ? selectedZoneNames.filter((zone) => zone !== zoneId)
          : [...selectedZoneNames, zoneId]
    );
  };

  const allZoneNames = useMemo(() => {
    return prescriptions?.map((pres) => pres.agrivar_zone);
  }, [prescriptions]);

  const selectedZones = useMemo(() => {
    return prescriptions?.filter((pres) =>
      selectAll ? true : selectedZoneNames.includes(pres.agrivar_zone)
    );
  }, [prescriptions, selectAll, selectedZoneNames]);

  const attributeValues: { [key: string]: number } = useMemo(() => {
    return AttributeOptions.reduce((acc, curr) => {
      let key = curr.key;

      return {
        ...acc,
        [key]:
          selectedZones?.reduce((sum, pres) => {
            if (key in pres) {
              const value =
                pres[key as keyof typeof pres] === 'nan'
                  ? 0
                  : parseFloat(pres[key as keyof typeof pres] || '0');
              return sum + value;
            }

            return sum;
          }, 0) / (selectedZones ? selectedZones.length : 1),
      };
    }, {});
  }, [selectedZones]);

  return (
    <div>
      <div className="card flex overflow-hidden">
        {/* Select Zone */}
        {prescriptions?.length > 0 && (
          <div className="flex flex-col items-start justify-between">
            <h4
              className={clsx(
                'text-md-bold px-4 py-2 bg-base-900 w-full',
                'bgPrimary'
              )}
            >
              Select Zone
            </h4>
            <span className=" h-full px-4 py-2 odd:bg-base-1000 even:bg-base-1100 evenOddBg w-full flex whitespace-nowrap">
              <Checkbox
                name={'all'}
                label={`All Zone`}
                rounded
                checked={selectAll}
                onChange={(checked) => {
                  setSelectAll(true);
                  setSelectedZoneNames([]);
                }}
              />
            </span>
            {prescriptions?.map((pres) => (
              <span
                key={pres.agrivar_zone1}
                className="h-full px-4 py-2 odd:bg-base-1000 even:bg-base-1100 evenOddBg w-full flex whitespace-nowrap"
              >
                <Checkbox
                  name={pres.agrivar_zone}
                  label={`Zone ${pres.agrivar_zone1}`}
                  rounded
                  checked={selectedZoneNames.includes(pres.agrivar_zone)}
                  value={pres.agrivar_zone}
                  onChange={(checked) => handleSelectZones(pres.agrivar_zone)}
                />
              </span>
            ))}
          </div>
        )}
        {/* Map */}
        <PrescriptionMap
          crop={crop}
          field={field}
          attribute={selectedAttribute}
          selectedZoneNames={selectAll ? allZoneNames : selectedZoneNames}
          onLegendDataChange={(data) => setLegendData(data)}
          scale={scale}
        />

        {/* Legend */}
        {legendData && (
          <div className="min-w-[115px]">
            <h4
              className={clsx(
                'text-md-bold px-4 py-2 bg-base-900 w-full whitespace-nowrap',
                'bgPrimary'
              )}
            >
              Zone Yield
            </h4>
            <div className="flex">
              <>
                <div className="h-full flex flex-col min-w-[68px]">
                  {legendData.values.map((value, i) => (
                    <span
                      key={`${i}-${value}`}
                      className="evenOddBg px-4 py-2 odd:bg-base-1000 even:bg-base-1100 w-full whitespace-nowrap"
                    >
                      <NumberDisplay value={value} />
                    </span>
                  ))}
                </div>
                <div className="flex justify-center bg-base-900 w-full p-3 group-data-[theme=Heartland]/root:bg-base-500">
                  <div className="h-full flex flex-col rounded-full overflow-hidden">
                    {legendData.colors.map((color, i) => (
                      <span
                        className="w-6"
                        key={`${i}-${color}`}
                        style={{
                          background: `linear-gradient(to bottom, ${legendData.colors[i]}, ${legendData.colors[i + 1] || legendData.colors[i]})`,
                          height: `${100 / legendData.colors.length}%`,
                        }}
                      ></span>
                    ))}
                  </div>
                </div>
              </>
            </div>
          </div>
        )}
      </div>

      {/* Bottom filters */}
      <Field className="mt-4 flex items-center gap-4">
        <Label className="whitespace-nowrap">
          Adjust Elevation Factor: {scale}
        </Label>
        <SliderInput
          onChange={(e) => setScale(Number(e.target.value))}
          min={0}
          max={10}
          value={scale}
        />
      </Field>

      <table className="w-full border-separate border-spacing-0 border border-base-900 rounded-xl mt-4">
        <tbody className="text-center text-sm-regular">
          <tr className="border border-base-900">
            <td
              className={clsx(
                'text-base-700 rounded-tl-xl px-3 py-2 border-r border-b border-base-900',
                'bgPrimary'
              )}
            >
              Attribute
            </td>
            {AttributeOptions.map((attr) => (
              <td
                className="evenOddBg odd:bg-block-fill even:bg-base-1000 border-r last:border-r-0 border-b border-base-900 space-y-2 px-3 py-2 last:rounded-tr-xl"
                key={attr.key}
              >
                <span className="flex flex-col items-center">
                  <Checkbox
                    name={attr.key}
                    rounded
                    checked={attr.key === selectedAttribute}
                    onChange={() => setSelectedAttribute(attr.key)}
                  />
                  <p className="mt-2 whitespace-nowrap">{attr.name}</p>
                </span>
              </td>
            ))}
          </tr>
          <tr>
            <td
              className={clsx(
                'text-base-700 rounded-bl-xl px-3 py-2 border-r border-base-900',
                'bgPrimary'
              )}
            >
              Value
            </td>
            {AttributeOptions.map((attr) => (
              <td
                className="evenOddBg odd:bg-block-fill even:bg-base-1000 border-r last:border-0 border-base-900 px-3 py-2 last:rounded-br-xl"
                key={attr.key}
              >
                <NumberDisplay value={attributeValues[attr.key]} />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
