import apiClient from './api-client';

export const getMarketBidAskApi = async (symbol: string) => {
  return apiClient.get(`/market/bid-ask?symbol=${symbol}
  `);
};

// future
export const getAllFuturesApi = async (root: string) => {
  const response = await apiClient.get(`/market/futures?root=${root}&productType=futures`);
  return response;
};

export const getPricesApi = async (symbol: string) => {
  const response = await apiClient.get(`/market/price?symbol=${symbol}`);
  return response.data;
};

export const getStrikePricesApi = async (symbol: string) => {
  const response = await apiClient.get(
    `/market/strikePrices?underlying=${symbol}`
  );
  return response.data;
};

export const getWatchListApi = async (user_id: string) => {
  const response = await apiClient.get(`/market/watch-list?userId=${user_id}`);
  return response.data;
};

export const getOptionsApi = async (symbol: string, option?: string) => {
  const response = await apiClient.get(
    `/market/options?symbol=${symbol}${option ? `&option=${option}` : ''}`
  );
  return response.data;
};

export const getBasisPricesAPI = (basisID: string) => {
  const response = apiClient.get(`/basis_prices?basisid=${basisID}`);
  // const response = fetch('https://4wr1k1zl17.execute-api.us-east-2.amazonaws.com/dev/basis_prices?basisid=${basisID}')
  return response;
};

export const getSpreadsApi = async (root: string) => {
  const response = await apiClient.get(
    `/market/futures?root=${root}&productType=spread`
  );
  return response.data;
};

export const getAlertsApi = async (userId: string) => {
  const response = await apiClient.get(`/market/alerts?userId=${userId}`);
  return response.data;
};

export const createAlertApi = async (alert: any) => {
  const response = await apiClient.post(`/market/alerts`, { ...alert });
  return response.data;
};

export const deleteAlertApi = async (
  id: string,
  date: string
): Promise<any> => {
  const response = await apiClient.delete(
    `/market/alerts?id=${id}&date=${date}`
  );
  return response.data;
};

export const createWatchListItemApi = async (item: any) => {
  const response = await apiClient.post(`/market/watch-list`, { ...item });
  return response.data;
};

export const deleteWatchListItemApi = async (payload: any) => {
  const response = await apiClient.delete(
    `/market/watch-list?id=${payload.id}&date=${payload.date}`
  );
  return response.data;
};

export const getBasisApi = async (
  userId: string,
  year: string,
  symbols: string[]
) => {
  const response = await apiClient.get(
    `/basis?userId=${userId}&year=${year}&symbols=${symbols.toString()}`
  );
  return response.data;
};
