import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import {
  useDeleteExpense,
  useExpenses,
} from '../../../hooks/expense/use-expense';
import Table, { TableConfigs } from '../../commons/table/Table';
import TableLayout from '../../dashboard/commons/TableLayout';
import EditFarmExpenseForm from './EditFarmExpenseForm';

const tableConfigs: TableConfigs = {
  cols: [
    {
      key: 'vendor',
      name: 'Vendor',
    },
    {
      key: 'vendor_address',
      name: 'Vendor Address',
    },
    {
      key: 'description',
      name: 'Description',
    },
    {
      key: 'expense_type',
      name: 'Type',
    },
    {
      key: 'expense_applied_to',
      name: 'Applied To',
    },
    {
      key: 'purchase_date',
      name: 'purchase_date',
    },
    {
      key: 'entry_date',
      name: 'Entry Date',
    },
    {
      key: 'expense_amount',
      name: 'Amount',
      type: 'currency',
    },
  ],
};

export default function FarmExpenseList() {
  const user = useUser();
  const year = useYearFilter();

  const { data, isFetching } = useExpenses(user?.id ?? '', year ?? '');
  const { mutate: deleteExpense } = useDeleteExpense();

  const rowActions = {
    edit: {
      onSelect: (item: any) => {},
      form: ({ item, onClose }: { item: any; onClose: () => void }) => {
        return <EditFarmExpenseForm data={item} onClose={onClose} />;
      },
    },
    delete: (item: any) => {
      if (confirm('Are you sure you want to delete this item?')) {
        deleteExpense({
          userId: user?.id ?? '',
          year: year ?? '',
          expenseId: item.expense_id,
        });
      }
    },
    note: {
      onSave: (item: any, value: any) => {},
      title: 'Add Expense Note',
    },
  };

  const filters = [
    { name: 'Expense Type', key: 'expenseType', handler: (value: any) => {} },
    { name: 'Vendor', key: 'vendor', handler: (value: any) => {} },
  ];

  return (
    <TableLayout title="Farm Expenses" filters={filters}>
      <Table
        configs={tableConfigs}
        data={data}
        pagination={{
          size: 10,
        }}
        rowActions={rowActions}
        loading={isFetching}
      />
    </TableLayout>
  );
}
