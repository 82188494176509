import { Button } from '@headlessui/react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import Select from '../../../components/commons/Select';
import YearSlider from './YearSlider';
import {
  areaSelectionOptions,
  mapRelationshipOptions,
} from '../../../constants/selectCommonConfigs';
import {
  useHistoricalInsights,
  useUserCropYears,
} from '../../../hooks/historical-insights/use-historical-insights';
import { useControlledUser, useUser } from '../../../contexts/auth-context';
import {
  getAreaAndYieldByCrop,
  useFarmYield,
} from '../../../hooks/farm/use-farm-yield';
import { useEffect, useMemo } from 'react';
import { useWebSocketDataV2 } from '../../../hooks/websocket/use-websocket-data';
import { useForm, useWatch } from 'react-hook-form';
import { useYearFilter } from '../../../contexts/app-filter-context';
import html2canvas from 'html2canvas';
function MapSelections() {
  const user = useControlledUser();
  const methods = useForm();

  const relationshipSelect = methods.watch('relationshipSelection', 'variety');
  const {
    setRgb,
    year,
    setYear,
    varietyYieldPerformance,
    setVarietyYieldPerformance,
    relationship,
    setRelationship,
    crop,
    setCrop,
    yieldChartRef,
    yieldPerformanceChartRef,
    tableChartRef,
    setPageSize,
  } = useHistoricalInsights();
  const filterYear = useYearFilter();
  const years = useUserCropYears(
    user?.id ? user?.id + '_' + filterYear : '45_' + filterYear,
    crop
  );
  const { data: res } = useFarmYield(user?.id || '', year || '');

  const msgYield: any = useWebSocketDataV2('Farm_Yield');

  const farmYieldData: any = useMemo(() => {
    if (!res) return [];
    const updatedRes = res.map((farmYield) => {
      if (msgYield && msgYield.data && msgYield.data.crop === farmYield.crop) {
        return {
          ...farmYield,
          area: msgYield.data.area || farmYield.area,
          yield: msgYield.data.yield || farmYield.yield,
          yield_chg: msgYield.data.yield_chg || farmYield.yield_chg,
          yield_chg14: msgYield.data.yield_chg14 || farmYield.yield_chg14,
          yield_per_acre_chg: msgYield.data.yield_chg || farmYield.yield_chg,
        };
      }
      return farmYield;
    });
    return getAreaAndYieldByCrop(updatedRes);
  }, [msgYield.data, res]);

  useEffect(() => {
    setRelationship(relationshipSelect);
  }, [relationshipSelect]);

  const downloadChart = () => {
    const chart = yieldChartRef.current;
    const chart2 = yieldPerformanceChartRef.current;
    setPageSize(100000);
    if (chart) {
      const base64Image = chart.toBase64Image();
      const link = document.createElement('a');
      link.href = base64Image;
      link.download = `${crop}_${relationship}_${year}_yield.png`;
      link.click();
    }
    setTimeout(() => {
      if (chart2) {
        const base64Image = chart2.toBase64Image();
        const link = document.createElement('a');
        link.href = base64Image;
        link.download = `${crop}_${relationship}_${year}_yield_performance.png`;
        link.click();
      }
    }, 1000);

    setTimeout(async () => {
      if (tableChartRef) {
        const canvas = await html2canvas(tableChartRef.current);
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = `${crop}_${relationship}_${year}_table_data.png`;
        link.click();
      }
    }, 2000);

    setTimeout(() => {
      setPageSize(5);
    }, 5000);
  };

  return (
    <div className="w-full flex mb-4 p-2 card">
      <div className="flex items-center space-x-4 justify-between">
        <p>Year</p>
        {years?.data && years?.data?.length > 0 && (
          <div className="flex items-center">
            <Select
              options={years?.data
                ?.map((year: string) => ({
                  value: year as string,
                  label: year as string,
                }))
                .sort(
                  (a: any, b: any) => parseInt(b.value) - parseInt(a.value)
                )}
              name="yearSelection"
              defaultValue={year}
              value={year}
              className="w-[120px]"
              onChange={(e) => {
                setYear(e.target.value);
              }}
            />
          </div>
        )}

        <p className="">Area Selection</p>
        <div className="flex items-center space-x-2">
          <Select
            className="w-[160px]"
            options={areaSelectionOptions}
            name="areaSelection"
            value={crop}
            onChange={(e) => setCrop(e.target.value)}
          />
        </div>
        <p className="text-secondary font-bold">
          {farmYieldData[crop]?.totalArea.toFixed(2) || 0} Acres
        </p>

        <p>Map Relationship</p>
        <div className="flex items-center space-x-2">
          <Select
            className="w-[160px]"
            options={mapRelationshipOptions}
            name="relationshipSelection"
            onChange={(e) => {
              setRelationship(e.target.value);
              setRgb([0, 0, 0]);
            }}
            value={relationship}
          />
        </div>

        <p>Display:</p>

        <label className="flex items-center space-x-2">
          <input
            type="radio"
            value="text"
            name="display"
            defaultChecked
            className="custom-radio"
            onChange={() => setVarietyYieldPerformance('x')}
          />
          <span>
            {relationship.slice(0, 1).toUpperCase()}
            {relationship.slice(1, relationship.length)}
          </span>
        </label>

        <label className="flex items-center space-x-2">
          <input
            type="radio"
            value="text"
            name="display"
            className="custom-radio"
            onChange={() => setVarietyYieldPerformance('yield')}
          />
          <span>Yield</span>
        </label>

        <label className="flex items-center space-x-2">
          <input
            type="radio"
            value="text"
            name="display"
            className="custom-radio"
            onChange={() => setVarietyYieldPerformance('yieldPerformance')}
          />
          <span>Yield Performance</span>
        </label>

        <div className="flex items-center space-x-2">
          <Button
            onClick={downloadChart}
            className="card items-center gap-2 flex flex-row max-w-[150px]  px-4 py-0"
          >
            <ArrowDownTrayIcon className="w-6 h-6" />
            <p>Download Charts</p>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default MapSelections;
