import HorizontalBar from '../../../components/historical-insights/Bar';
import PercentageHorizontalBar from '../../../components/historical-insights/PercentageBar';
import { useUser } from '../../../contexts/auth-context';
import {
  useHistoricalInsights,
  useTableData,
} from '../../../hooks/historical-insights/use-historical-insights';

function YieldCharts() {
  const user = useUser();
  const { year, relationship, crop } = useHistoricalInsights();
  const tableData = useTableData(user?.id || '', crop, relationship, year);

  const tableLength = tableData?.data?.length * 200;

  return (
    <div className={'space-y-4'}>
      {/* yield & variety */}
      <div className="card h-[280px] px-4">
        <HorizontalBar />
      </div>

      <div className="card h-[280px] px-4">
        <PercentageHorizontalBar />
      </div>
    </div>
  );
}

export default YieldCharts;
