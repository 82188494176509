import {
  ArrowsPointingOutIcon,
  CubeTransparentIcon,
  EyeIcon,
  EyeSlashIcon,
  PaintBrushIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';
import { theme } from 'twin.macro';
import CircleIcon from '../../../assets/icons/CircleIcon';
import MapZoomInIcon from '../../../assets/icons/MapZoomInIcon';
import MapZoomOutIcon from '../../../assets/icons/MapZoomOutIcon';
import PolygonIcon from '../../../assets/icons/PolygonIcon';
import RectangleIcon from '../../../assets/icons/RectangleIcon';
import SquareIcon from '../../../assets/icons/SquareIcon';
import { NETWORK_PARTNER } from '../../../constants';
import { useUser } from '../../../contexts/auth-context';
import { useMapContext } from '../../../contexts/map-context';
import clsx from 'clsx';
import { title } from 'process';

const fillColors = (networkPartner: string | undefined) => {
  let inactive = theme`colors.base.000`;
  let active = theme`colors.yellow`;

  switch (networkPartner) {
    case NETWORK_PARTNER.heartland:
      inactive = theme`colors.base.800`;
      break;
  }

  return {
    inactive,
    active,
  };
};

function MapToolbar() {
  const {
    setSelectedDrawMode,
    selectedDrawMode,
    viewState,
    setViewState,
    myFeatureCollection,
    setMyFeatureCollection,
    selectedFeatureIndexes,
    setSelectedFeatureIndexes,
    setModalMode,
  } = useMapContext();
  const user = useUser();

  const viewActions = [
    {
      title: 'Move',
      icon: (props?: any) => <ArrowsPointingOutIcon {...props} />,
      handler: () => {
        setModalMode('');
        setSelectedDrawMode(0);
      },
      mode: 0,
    },
    {
      title: 'Zoom In',
      icon: (props?: any) => <MapZoomInIcon {...props} />,
      handler: () => {
        setViewState({ ...viewState, zoom: viewState.zoom + 1 });
      },
    },
    {
      title: 'Zoom Out',
      icon: (props?: any) => <MapZoomOutIcon {...props} />,
      handler: () => {
        setViewState({ ...viewState, zoom: viewState.zoom - 1 });
      },
    },
  ];

  const drawActions = [
    {
      title: 'Draw Polygon',
      icon: (props?: any) => <PolygonIcon {...props} />,
      handler: () => {
        setModalMode('drawingModeSelected');
        setSelectedDrawMode(1);
      },
      mode: 1,
    },
    {
      title: 'Draw Square',
      icon: (props?: any) => <SquareIcon {...props} />,
      handler: () => {
        setModalMode('drawingModeSelected');
        setSelectedDrawMode(2);
      },
      mode: 2,
    },
    {
      title: 'Draw Rectangle',
      icon: (props?: any) => <RectangleIcon {...props} />,
      handler: () => {
        setModalMode('drawingModeSelected');
        setSelectedDrawMode(3);
      },
      mode: 3,
    },
    {
      title: 'Draw Circle',
      icon: (props?: any) => <CircleIcon {...props} />,
      handler: () => {
        setModalMode('drawingModeSelected');
        setSelectedDrawMode(4);
      },
      mode: 4,
    },
    {
      title: 'Drag & Draw Polygon-String',
      icon: (props?: any) => <PaintBrushIcon {...props} />,
      handler: () => {
        setModalMode('drawingModeSelected');
        setSelectedDrawMode(5);
      },
      mode: 5,
    },
  ];

  const editActions = [
    {
      title: 'Edit Polygons',
      icon: (props?: any) => <PencilSquareIcon {...props} />,
      handler: () => {
        setModalMode('editPolygons');
        setSelectedDrawMode(6);
      },
      mode: 6,
    },
    {
      title: 'Transform Polygon',
      icon: (props?: any) => <CubeTransparentIcon {...props} />,
      handler: () => {
        setModalMode('transformPolygons');
        setSelectedDrawMode(7);
      },
      mode: 7,
    },
    {
      title: 'Delete Polygons',
      icon: (props?: any) => <TrashIcon {...props} />,
      handler: () => {
        let newFeatures = myFeatureCollection.features.filter(
          (feature, index) => {
            return !selectedFeatureIndexes.includes(index);
          }
        );
        setMyFeatureCollection({
          type: 'FeatureCollection',
          features: newFeatures,
        });
        setSelectedFeatureIndexes([]);
      },
    },
    {
      title: 'Unselect All',
      icon: (props?: any) => <EyeSlashIcon {...props} />,
      handler: () => {
        setSelectedFeatureIndexes([]);
      },
    },
    {
      title: 'Select All',
      icon: (props?: any) => <EyeIcon {...props} />,
      handler: () => {
        // make an array from 0 - myFeatureCollection.features.length
        let indexes = Array.from(
          Array(myFeatureCollection.features.length).keys()
        );
        setSelectedFeatureIndexes(indexes);
      },
    },
  ];

  return (
    <div className="">
      <div className="grid grid-cols-3 gap-4">
        <div className="flex flex-col gap-4 items-start card p-4">
          <p className="display-xs-bold w-full text-center border-b">View</p>
          {viewActions.map((action) => (
            <div
              onClick={action.handler}
              className="flex cursor-pointer gap-2 items-center"
            >
              {action.icon({
                fill:
                  selectedDrawMode === action.mode
                    ? theme`colors.yellow`
                    : fillColors?.(user?.network_partner)?.inactive,
                className: 'size-6',
              })}
              <p
                className={clsx(
                  selectedDrawMode === action.mode
                    ? 'text-yellow'
                    : 'text.base.800'
                )}
              >
                {action.title}
              </p>
            </div>
          ))}
        </div>

        <div className="flex flex-col gap-4 items-start card p-4">
          <p className="display-xs-bold w-full text-center border-b">Draw</p>
          {drawActions.map((action) => (
            <div
              onClick={action.handler}
              className="flex cursor-pointer gap-2 items-center"
            >
              {action.icon({
                fill:
                  selectedDrawMode === action.mode
                    ? theme`colors.yellow`
                    : fillColors?.(user?.network_partner)?.inactive,
                className: 'size-6',
              })}
              <p
                className={clsx(
                  selectedDrawMode === action.mode
                    ? 'text-yellow'
                    : 'text.base.800'
                )}
              >
                {action.title}
              </p>
            </div>
          ))}
        </div>

        <div className="flex flex-col gap-4 items-start card p-4">
          <p className="display-xs-bold w-full text-center border-b">Edit</p>
          {editActions.map((action) => (
            <div
              onClick={action.handler}
              className="flex cursor-pointer gap-2 items-center"
            >
              {action.icon({
                fill:
                  selectedDrawMode === action.mode
                    ? theme`colors.yellow`
                    : fillColors?.(user?.network_partner)?.inactive,
                className: 'size-6',
              })}
              <p
                className={clsx(
                  selectedDrawMode === action.mode
                    ? 'text-yellow'
                    : 'text.base.800'
                )}
              >
                {action.title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MapToolbar;
