import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useUser } from '../../../../contexts/auth-context';
import { useDeleteLease, useLeases } from '../../../../hooks/lease/use-lease';
import { LeaseItem } from '../../../../types/lease';
import Table, { RowActions, TableConfigs } from '../../../commons/table/Table';
import TableLayout from '../../commons/TableLayout';
import EditLeaseForm from './EditLeaseForm';

interface LeaseListProps {}

const tableConfigs: TableConfigs = {
  cols: [
    {
      key: 'lease_type',
      name: 'Type',
    },
    {
      key: 'lessee_or_lessor',
      name: 'Lessor / Lessee',
    },
    {
      key: 'lease_name',
      name: 'Lease Name',
    },
    {
      key: 'share_of_production_pct',
      name: 'Production Share',
    },
    { key: 'share_of_revenue_pct', name: 'Revenue Share' },
    { key: 'base_rent_per_acre', name: 'Rent / Acre' },
    { key: 'date_pymt', name: 'Payment Date' },
    { key: 'field_applied', name: 'Field Applied' },
    { key: 'valuation', name: 'Valuation' },
  ],
};

export default function LeaseList(props: LeaseListProps) {
  const user = useUser();
  const year = useYearFilter();
  const { data, isFetching } = useLeases(user?.id ?? '', year ?? '');

  const { mutateAsync: deleteLease } = useDeleteLease();

  const rowActions: RowActions = {
    edit: {
      onSelect: (item: LeaseItem) => {},
      form: ({ item, onClose }) => (
        <EditLeaseForm data={item} onCancel={onClose} />
      ),
    },
    delete: async (item: LeaseItem) => {
      deleteLease({
        userId: user?.id ?? '',
        year: year ?? '',
        leaseId: item.unique_lease_id,
      });
    },
  };
  return (
    <TableLayout title="Current Leases">
      <Table
        configs={tableConfigs}
        data={data}
        title={{ name: 'Current Leases', tooltip: 'Current Leases' }}
        loading={isFetching}
        rowActions={rowActions}
      />
    </TableLayout>
  );
}
