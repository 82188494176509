import { useMemo } from 'react';
import { FieldPrescriptionRes } from '../../../../types';
import { convertToFloat } from '../../../../utils';
import Checkbox from '../../../commons/Checkbox';
import Table, { TableConfigs } from '../../../commons/table/Table';

import TableLayout from '../../commons/TableLayout';

interface YieldGoalListProps {
  selectedZone: FieldPrescriptionRes;
  data: FieldPrescriptionRes[];
  onSelect: (zone: string) => void;
  loading?: boolean;
  setIsEditing: (value: boolean) => void;
}

export default function YieldGoalList({
  selectedZone,
  data,
  onSelect,
  loading,
  setIsEditing,
}: YieldGoalListProps) {
  const enhancedData = useMemo(() => {
    return data.map((item) => {
      return {
        ...item,
        yield_goal: convertToFloat(
          item?.user_adjusted_yield || item.zone_yield
        ),
      };
    });
  }, [data]);

  const tableConfigs: TableConfigs = useMemo(() => {
    return {
      cols: [
        {
          name: 'Select Zone',
          key: 'zone',
          type: 'element',
          element: (item: FieldPrescriptionRes) => (
            <Checkbox
              name="zone"
              label={`Zone ${item.agrivar_zone1}`}
              checked={item.agrivar_zone === selectedZone.agrivar_zone}
              onChange={(checked: boolean) => {
                checked && onSelect(item.agrivar_zone);
              }}
              rounded
            />
          ),
        },
        { name: 'Acres', key: 'area', unit: 'acres' },
        {
          name: 'Yield Goal',
          key: 'yield_goal',
          type: 'number',
          total: true,
        },
        {
          name: 'Yield Goal Probability',
          key: 'yield_probability',
          type: 'number',
        },
        {
          name: 'Average Yield',
          key: 'average_yield',
          type: 'number',
        },
        {
          name: 'Yield Risk',
          key: 'sigma',
          type: 'number',
        },
        {
          name: 'Current Yield Estimate',
          key: 'predicted_yield',
          type: 'number',
        },
        {
          name: 'Revenue',
          key: 'revenue_per_acre',
          type: 'number',
        },
      ],
    };
  }, [onSelect, selectedZone.agrivar_zone]);

  const rowActions = {
    edit: {
      onSelect: (item: FieldPrescriptionRes) => {
        onSelect(item.agrivar_zone);
        setIsEditing(true);
      },
    },
    delete: (item: FieldPrescriptionRes) => {},
    note: {
      onSelect: (item: FieldPrescriptionRes) => {
        onSelect(item.agrivar_zone);
      },
      onSave: (item: FieldPrescriptionRes, value: string) => {},
      onSaveAll: (item: FieldPrescriptionRes, value: string) => {},
      title: 'Add Zone Note',
    },
  };

  return (
    <TableLayout title={'Zone Yield Goals'}>
      <Table
        configs={tableConfigs}
        data={enhancedData}
        loading={loading}
        rowActions={rowActions}
        hasTotalRow
      />
    </TableLayout>
  );
}
