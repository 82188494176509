import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import {
  createPrescriptionProductApi,
  deleteFieldPrescriptionHistoryApi,
  deletePrescriptionProductApi,
  getFieldPrescriptionApi,
  getFieldPrescriptionHistoryApi,
  getPrescriptionProductApi,
  updateFieldPrescriptionApi,
  updatePrescriptionProductApi,
} from '../../api/field-api';
import {
  AddFertilizerPayload,
  AddSeedingRateVarietyPayload,
  DeletePrescriptionProductPayload,
  FieldPrescriptionRes,
  PrescriptionProductRes,
  PrescriptionUpdatePayload,
} from '../../types';
import { PrescriptionHistoryItem } from '../../types/history';

export const useFieldPrescription = (
  userIdYear: string,
  fieldIndex?: string
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['field/prescription', userIdYear, fieldIndex],
    queryFn: () =>
      getFieldPrescriptionApi({ userIdYear, fieldIndex: fieldIndex }),
    enabled: !!userIdYear && !!fieldIndex,
  });

  return {
    ...rest,
    data: data?.data as FieldPrescriptionRes[] | [],
  };
};

export const usePrescriptionProduct = ({
  type,
  year,
  crop,
  userId,
}: {
  type: string;
  year: string;
  crop: string;
  userId: string;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: ['field/prescription/product', userId, type, year, crop],
    queryFn: () => getPrescriptionProductApi({ userId, type, year, crop }),
    enabled: !!year && !!crop && !!userId && !!type,
  });
  return {
    data: data?.data as PrescriptionProductRes[] | [],
    ...rest,
  };
};

// Mutation
export const useUpdateFieldPrescription = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ['UPDATE', 'field/prescription'],
    mutationFn: (payload: PrescriptionUpdatePayload) =>
      updateFieldPrescriptionApi(payload),
    onSuccess: () => {},
    onError: (error) => {},
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['field/prescription'],
      });
      queryClient.invalidateQueries({
        queryKey: ['field/prescription/product'],
      });
    },
  });

  return mutation;
};

export const useCreatePrescriptionProduct = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ['CREATE', 'field/prescription/product', 'seed'],
    mutationFn: (
      payload: AddSeedingRateVarietyPayload | AddFertilizerPayload
    ) => createPrescriptionProductApi(payload),
    onSuccess: () => {},
    onError: () => {},
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['field/prescription'],
      });
      queryClient.invalidateQueries({
        queryKey: ['field/prescription/product'],
      });
    },
  });

  return mutation;
};

export const useUpdatePrescriptionProduct = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ['UPDATE', 'field/prescription/product', 'seed'],
    mutationFn: (payload: PrescriptionProductRes) =>
      updatePrescriptionProductApi(payload),
    onSuccess: () => {},
    onError: (error) => {},
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['field/prescription'],
      });
      queryClient.invalidateQueries({
        queryKey: ['field/prescription/product'],
      });
    },
  });

  return mutation;
};

export const useDeletePrescriptionProduct = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['DELETE', 'field/prescription/product', 'seed'],
    mutationFn: (payload: DeletePrescriptionProductPayload) =>
      deletePrescriptionProductApi(payload),
    onSuccess: () => {
      enqueueSnackbar('Deleted successfully', {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Error', {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['field/prescription'],
      });
      queryClient.invalidateQueries({
        queryKey: ['field/prescription/product'],
      });
    },
  });
};

export const useGetFieldPrescriptionHistory = ({
  year,
  field,
}: {
  year: string;
  field: string;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: ['field/prescription/history', year, field],
    queryFn: () => getFieldPrescriptionHistoryApi({ year, field }),
    enabled: !!year && !!field,
  });

  return {
    data: data?.data as PrescriptionHistoryItem[] | [],
    ...rest,
  };
};

export const useDeletePrescriptionHistory = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['DELETE', 'field/prescription/history'],
    mutationFn: (payload: { year: string; field: string }) =>
      deleteFieldPrescriptionHistoryApi(payload),
    onSuccess: () => {
      enqueueSnackbar('Deleted successfully', {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Error', {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['field/prescription/history'],
      });
    },
  });
};
