import { enqueueSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useMainUser, useUser } from '../../../../contexts/auth-context';
import {
  usePrescriptionProduct,
  useUpdateFieldPrescription,
} from '../../../../hooks/field/use-field-prescription';
import {
  FieldPrescriptionRes,
  FieldPrescriptionWithProducts,
  PrescriptionProductRes,
} from '../../../../types';
import DisclosureContentLayout from '../../commons/DisclosureContentLayout';
import FlipLayout from '../../commons/FlipLayout';
import { getZone, getZonesWithProducts } from '../helpers';
import FertilizerForm from './FertilizerForm';
import FertilizerInfo from './FertilizerInfo';
import FertilizerList from './FertilizerList';

export type FertilizerType = {
  id: string;
  name: string;
  shortName: string;
};

export const Fertilizers = [
  {
    id: 'N',
    name: 'Nitrogen',
    shortName: 'N',
  },
  {
    id: 'P',
    name: 'Phosphorus',
    shortName: 'P',
  },
  {
    id: 'K',
    name: 'Potassium',
    shortName: 'K',
  },
];

export default function AddFertilizer({
  crop,
  presctiptions,
  loading,
}: {
  crop: string;
  presctiptions: FieldPrescriptionRes[];
  loading: boolean;
}) {
  const user = useUser();
  const mainUser = useMainUser();
  const year = useYearFilter();
  const { data: fertilizers, isFetching } = usePrescriptionProduct({
    type: 'fertilizer',
    year: year ?? '',
    crop,
    userId: user?.id || '',
  });
  const { mutateAsync: update, isPending: isUpdating } =
    useUpdateFieldPrescription();

  const [list, setList] = useState<FieldPrescriptionWithProducts[]>([]);
  const [selectedZone, setSelectedZone] = useState(
    () => presctiptions[0]?.agrivar_zone ?? ''
  );
  const [isEditing, setIsEditing] = useState(false);
  const [selectedProductByZone, setSelectedProductZone] = useState(
    list.reduce(
      (acc, item) => {
        acc[item.agrivar_zone] = {} as PrescriptionProductRes;
        return acc;
      },
      {} as Record<string, PrescriptionProductRes>
    )
  );

  const handleSelectedFertilizer = (
    zone: string,
    fertilizer: PrescriptionProductRes
  ) => {
    setSelectedProductZone((prev) => ({
      ...prev,
      [zone]: fertilizer,
    }));
  };

  useEffect(() => {
    setSelectedProductZone((prev) => {
      return {
        ...prev,
        [selectedZone]:
          fertilizers?.find(
            (item) => getZone(item.user_id_agrivar_zone) === selectedZone
          ) || ({} as PrescriptionProductRes),
      };
    });
  }, [fertilizers, list, selectedZone]);

  useEffect(() => {
    setList(getZonesWithProducts(presctiptions, fertilizers, 'fertilizer'));
  }, [presctiptions, fertilizers]);

  const selectZoneObj = useMemo(() => {
    return (
      list?.find((item) => item.agrivar_zone === selectedZone) ??
      ({} as FieldPrescriptionWithProducts)
    );
  }, [list, selectedZone]);

  // Update by knob
  const handleUpdate = async (value: number, fertilizerShortName: string) => {
    if (!user?.id || !year || !crop || !selectZoneObj || !mainUser) return;
    try {
      await update({
        user: mainUser,
        user_id_crop: `${user.id}_${year}-${crop}`,
        agrivar_zone: selectZoneObj.agrivar_zone,
        key: `${fertilizerShortName}_rate`,
        field: selectZoneObj.field,
        field_index: selectZoneObj.field_index,
        user_id: user.id,
        year: year,
        value: value.toString(),
      });
      enqueueSnackbar('Updated successfully', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Failed to update', { variant: 'error' });
      console.error('Failed to update seeding rate', error);
    }
  };

  return (
    <div className="space-y-8">
      {Fertilizers?.map((fertilizer) => (
        <DisclosureContentLayout
          key={fertilizer.id}
          left={
            <>
              {isEditing ? (
                <FertilizerForm
                  key={JSON.stringify(selectedProductByZone[selectedZone])}
                  title={
                    <h4>
                      Selected Zone:{' '}
                      <span className="text-primary">{`Zone ${selectZoneObj.agrivar_zone1}`}</span>
                    </h4>
                  }
                  edit
                  setIsEditing={setIsEditing}
                  selectedZone={selectZoneObj}
                  fertilizer={fertilizer}
                  crop={crop}
                  selectedProduct={selectedProductByZone[selectedZone]}
                  zones={list}
                />
              ) : (
                <FlipLayout disabled>
                  {({ side, setSide }) => (
                    <div>
                      {side === 'front' && (
                        <FertilizerInfo
                          key={JSON.stringify(
                            selectedProductByZone[selectedZone]
                          )}
                          onFlip={() => setSide('back')}
                          fertilizer={fertilizer}
                          selectedZone={selectZoneObj}
                          onUpdate={handleUpdate}
                          loading={isUpdating || isFetching}
                          selectedProduct={selectedProductByZone[selectedZone]}
                        />
                      )}
                      {side === 'back' && (
                        <FertilizerForm
                          fertilizer={fertilizer}
                          crop={crop}
                          selectedZone={selectZoneObj}
                          setIsEditing={setIsEditing}
                          zones={list}
                        />
                      )}
                    </div>
                  )}
                </FlipLayout>
              )}
            </>
          }
          right={
            <FertilizerList
              fertilizer={fertilizer}
              data={list}
              selectedZone={selectZoneObj}
              onSelect={setSelectedZone}
              loading={loading || isFetching}
              setIsEditing={setIsEditing}
              setSelectedProductByZone={handleSelectedFertilizer}
              selectedProductByZone={selectedProductByZone}
            />
          }
        />
      ))}
    </div>
  );
}
