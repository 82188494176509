import {
  Disclosure as HDisclosure,
  DisclosureButton as HDisclosureButton,
  DisclosurePanel as HDisclosurePanel,
} from '@headlessui/react';
import {
  ChevronDownIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { ReactNode } from 'react';

interface DisclosureProps {
  title: string | ReactNode;
  tooltip?: string | ReactNode;
  children: string | ReactNode;
  className?: string;
  buttonClassName?: string;
  panelClassName?: string;
  icon?: ReactNode;
}

export default function Disclosure({
  title,
  children,
  tooltip,
  className,
  buttonClassName,
  panelClassName,
  icon,
}: DisclosureProps) {
  return (
    <HDisclosure as="div" className={clsx('disclosure w-full', className)}>
      <HDisclosureButton
        className={clsx(
          'disclosureButton group w-full py-3 px-4 bg-block-fill border-2 border-base-1000 rounded-xl',
          'data-[open]:rounded-b-none',
          'data-[open]:bg-base-1100',
          buttonClassName
        )}
      >
        <span className="flex items-center justify-between w-full">
          <span className="flex items-center space-x-2">
            {icon}
            <p className="display-sm-bold">{title}</p>
            {tooltip && (
              <InformationCircleIcon className="size-6 fill-yellow" />
            )}
          </span>
          <span className="group-data-[open]:rotate-180">
            <ChevronDownIcon className="size-4" />
          </span>
        </span>
      </HDisclosureButton>
      <HDisclosurePanel
        className={clsx(
          'disclosurePanel border-2 border-t-0 border-base-1000 border-t-transparent rounded-t-none overflow-hidden rounded-xl',
          panelClassName
        )}
      >
        {children}
      </HDisclosurePanel>
    </HDisclosure>
  );
}
