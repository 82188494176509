import { Field, Checkbox as HCheckbox, Label } from '@headlessui/react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

interface CheckboxProps {
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
  label?: string;
  labelClassname?: string;
  className?: string;
  name: string;
  disabled?: boolean;
  value?: string;
  rounded?: boolean;
  checkboxClassname?: string;
}

const CheckMark = () => {
  return (
    <svg
      className="checkmark stroke-base-000 opacity-0 group-data-[checked]/checkbox:opacity-100"
      viewBox="0 0 14 12"
      fill="none"
      width={14}
      height={12}
    >
      <path
        d="M1 6.11536L5 10.1154L12.5 1.61536"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const RoundMark = () => {
  return (
    <span className="opacity-0 group-data-[checked]/checkbox:opacity-100 !bg-primary size-3.5 rounded-full"></span>
  );
};

function Checkbox(props: CheckboxProps) {
  const {
    label,
    className,
    name,
    disabled,
    defaultChecked,
    labelClassname,
    checked,
    value,
    rounded = false,
    checkboxClassname,
    ...rest
  } = props;
  const formContext = useFormContext();
  const error = formContext?.formState.errors[name];

  const checkBoxStyle = rounded
    ? 'rounded-full border-base-500 data-[checked]:bg-base-800'
    : 'rounded border-base-000 data-[checked]:bg-base-800';

  if (!formContext) {
    // In case you dont get the values by handleSubmit of react-hook-form
    // you forgot to wrap the form with <FormProvider> in the parent component
  }

  const { onChange, ...field } = formContext?.register(name) || {};
  const formValue = formContext?.watch(name);

  return (
    <>
      <Field className={clsx('flex items-center space-x-2', className)}>
        {!formContext && (
          <HCheckbox
            defaultChecked={defaultChecked}
            checked={checked}
            className={clsx(
              'group/checkbox checkbox flex items-center justify-center size-6 border-2 bg-opacity-0 flex-shrink-0',
              checkBoxStyle,
              checkboxClassname,
              { rounded: rounded }
            )}
            value={value}
            {...rest}
          >
            {/* Checkmark icon */}
            {rounded ? <RoundMark /> : <CheckMark />}
          </HCheckbox>
        )}

        {formContext && (
          <HCheckbox
            className={clsx(
              'checkbox group flex items-center justify-center size-6 border-2 bg-opacity-0 flex-shrink-0',
              checkBoxStyle,
              checkboxClassname,
              { rounded: rounded }
            )}
            onChange={(checked) => {
              onChange({
                target: {
                  value: checked ? (value ? value : checked) : null,
                  checked: checked,
                  name: name,
                },
              });
            }}
            {...(typeof formValue === 'boolean' && { checked: formValue })}
            defaultChecked={defaultChecked}
            {...field}
          >
            {/* Checkmark icon */}
            {rounded ? <RoundMark /> : <CheckMark />}
          </HCheckbox>
        )}

        {label && (
          <Label
            className={clsx('cursor-pointer text-md-regular', labelClassname)}
          >
            {label}
          </Label>
        )}
      </Field>
      {error && <p className="text-red mt-2">{`${error?.message}`}</p>}
    </>
  );
}

export default Checkbox;
