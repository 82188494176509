import { enqueueSnackbar } from 'notistack';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import { useUpdateExpense } from '../../../hooks/expense/use-expense';
import { useFieldPolygonsByYear } from '../../../hooks/field/use-field-polygons';
import { ExpenseItem } from '../../../types';
import { parseMoney } from '../../../utils';
import Button from '../../commons/Button';
import ComboSelectInput from '../../commons/inputs/ComboSelectInput';
import TextInput from '../../commons/inputs/TextInput';
import { AwaitSelect } from '../../commons/Select';

interface EditCropInsuranceFormProps {
  data: ExpenseItem;
  onClose: () => void;
}

type CropInsuranceFormValues = {
  purchase_date: string;
  vendor: string;
  vendor_address: string;
  isPaid: boolean;
  description: string;
  expense_amount: number;
  entry_date: string;
};

export default function EditCropInsuranceForm({
  data,
  onClose,
}: EditCropInsuranceFormProps) {
  const user = useUser();
  const year = useYearFilter();

  // Field options
  const { data: allFields, isFetching: isFetchingField } =
    useFieldPolygonsByYear(user?.id ?? '', year ?? '');
  const fieldOptions = useMemo(() => {
    return allFields?.map((field) => ({
      label: field.field,
      value: field.field,
    }));
  }, [allFields]);

  const { mutateAsync: updateExpense, isPending } = useUpdateExpense();
  const form = useForm({
    defaultValues: {
      ...(data as any),
    },
  });

  const handleSubmit = async (values: CropInsuranceFormValues) => {
    if (!user || !year) return;
    const updates = Object.entries(values).map(([key, value]) => ({
      key,
      value: value?.toString() ?? '',
    }));

    try {
      await updateExpense({
        userId: user.id,
        year: year,
        expenseId: data.expense_id,
        updates,
      });
      onClose();
    } catch (error) {
      enqueueSnackbar('Failed to update expense', { variant: 'error' });
    }
  };

  return (
    <div>
      <p className="mb-2">Edit Crop Insurance</p>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="rounded-lg bg-base-900 hlBgBase200 p-4"
        >
          <div className="grid grid-cols-4 gap-4">
            <AwaitSelect
              name={`field_name`}
              label="Field Name"
              required
              options={fieldOptions}
              isLoading={isFetchingField}
            />
            <TextInput name={`legal_description`} label="Field Legal" />
            <ComboSelectInput
              name={`crop`}
              label="Commodity"
              options={[]}
              required
            />
            <ComboSelectInput
              name={`intended_use`}
              label="Intended Use"
              options={[]}
              required
            />
            <ComboSelectInput
              name={`irrigation_practice`}
              label="Irrigation Practice"
              options={[]}
              required
            />
            <ComboSelectInput
              name={`organic_practice`}
              label="Organic Practice"
              options={[]}
              required
            />
            <TextInput name="acres" type="number" label="Acres" required />
            <TextInput
              name="approved_yield"
              type="number"
              label="APH"
              required
            />
            <TextInput
              name="base_price"
              type="number"
              label="Base Price"
              required
            />
            <TextInput
              name="percent_coverage"
              type="number"
              label="Coverage Percent"
              required
            />
            <TextInput
              name="premium_per_acre"
              type="number"
              label="Premium per Acre"
              required
            />
            <TextInput
              name="share_percentage"
              type="number"
              label="Share Percent"
              required
            />
          </div>

          <div className="mt-6 flex justify-end space-x-4">
            <Button
              type="submit"
              color="primary"
              size="sm"
              className="!w-fit"
              loading={isPending}
              disabled={
                isPending || !form.formState.isDirty || !form.formState.isValid
              }
            >
              Save
            </Button>
            <Button color="primary" size="sm" className="!w-fit">
              Move to List
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
