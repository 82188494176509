import { CalendarDaysIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';
import {
  CropToCode,
  MonthToCode,
  ThemeColors,
  cropToMonthOptions,
  yearOptions,
} from '../../../constants';
import { useUser } from '../../../contexts/auth-context';
import { useMarketContext } from '../../../contexts/market-context';
import { useCompanyLocationOptions } from '../../../hooks/basis/use-basis';
import { useCreateAlert } from '../../../hooks/market/use-alerts';
import { useStrikePrices } from '../../../hooks/market/use-options';
import Button from '../../commons/Button';
import TextInput from '../../commons/inputs/TextInput';
import Select from '../../commons/Select';
import { commodityOptions } from './PriceChart';

interface CreateAlertProps {
  isCreatingNewAlert: boolean;
  setIsCreatingNewAlert: React.Dispatch<React.SetStateAction<boolean>>;
  creatingNewAlertType: string;
}

interface Alert {
  id: string;
  userId: string;
  crop: string;
  condition: string;
  price: string;
  change: string;
  status: string;
  type: string;
  date: string;
  dateRange: string;
  priceRange: string;
  alertVia: string;
  strikePrice?: string;
  optionType?: string;
  basisLocation?: string;
  basisValue?: string;
  month1?: string;
  month2?: string;
  year1?: string;
  year2?: string;
}

interface AlertRecord {
  userId: string;
  crop: string;
  price: string;
  status: string;
  dateRange: string;
  priceRange: string;
  alertVia?: string;
  date: string;
  type: string;
  strikePrice?: string;
  optionType?: string;
  basisLocation?: string;
  basisCompany?: string;
  basisValue?: string;
  month1?: string;
  month2?: string;
  year1?: string;
  year2?: string;
}

const CreateAlert: React.FC<CreateAlertProps> = ({
  isCreatingNewAlert,
  setIsCreatingNewAlert,
  creatingNewAlertType: contractTypeValue,
}) => {
  const methods = useForm();
  const { register, watch } = methods;

  const [alerts, setAlerts] = useState<Alert[]>([]);
  const user = useUser();
  const queryClient = useQueryClient();
  const commodity = useRef<string>('Corn');

  const { trigger, setTrigger } = useMarketContext();
  const contractType = useRef<string>('basis');
  const { mutate: createAlert } = useCreateAlert(user?.id || '');

  const crop = watch('crop', 'Corn');
  const month = watch('month', 'december');
  const year = watch('year', '2024');
  const optionType = watch('optionType', 'call');


  const underlying = useMemo(() => {
    return CropToCode[crop] + MonthToCode[month] + year.slice(3);
  }, [crop, month, year]);

  const strikePrices = useStrikePrices(underlying);

  const companyLocationOptions = useCompanyLocationOptions(
    user?.id!,
    year,
    underlying
  );

  const [selectedBasisLocationCompnay, setSelectedBasisLocationCompany] = useState<string>(companyLocationOptions[0]?.value);


 


  const onSubmit = (data: any) => {
    if (data.price.includes('$') || data.price.length === 0) {
      alert(
        'Invalid price format. Please enter a valid price without the "$" symbol.'
      );
      return;
    }
    if (contractTypeValue === 'basis' && ( data.basisValue.includes('$') || data.basisValue.length === 0 || !data.basisLocation)) {
      alert(
        'Invalid basis value format. Please enter a valid company, location, and basis value without the "$" symbol.'
      );
      return;
    }

    const date = new Date(Date.parse(data.month + ' 1, 2020')); // Using any non-leap year
    const monthNumber = (date.getMonth() + 1).toString().padStart(2, '0');

    const newAlert: AlertRecord = {
      userId: user?.id ?? '',
      crop: data.crop,
      price: data.price,
      status: 'active', // Default status
      type: contractTypeValue,
      date: `${data.year}-${monthNumber}`,
      dateRange: data.triggerPoint,
      priceRange: data.timing,
      alertVia: data.alertVia, // Get the selected notification method
    };
    if (data.strikePrice) {
      newAlert.strikePrice = data.strikePrice;
    }
    if (data.optionType) {
      newAlert.optionType = data.optionType;
    }

    if (data.basisLocation) {
      newAlert.basisLocation = data.basisLocation.split(' & ')[1];
      newAlert.basisCompany = data.basisLocation.split(' & ')[0];
    }
    if (data.basisValue) {
      newAlert.basisValue = data.basisValue;
    }
    if (data.month1) {
      newAlert.month1 = data.month1;
    }
    if (data.month2) {
      newAlert.month2 = data.month2;
    }
    if (data.year1) {
      newAlert.year1 = data.year1;
    }
    if (data.year2) {
      newAlert.year2 = data.year2;
    }


    // Call the createAlert mutation
    createAlert(newAlert, {
      onSuccess: () => {
        // Invalidate and refetch alerts
        queryClient.invalidateQueries([
          'market/alerts',
          user?.id ?? '',
        ] as InvalidateQueryFilters);
        setIsCreatingNewAlert(false);
        alert('Alert created successfully');
      },
      onError: (error: Error) => {
        console.error('Error creating alert:', error);
        alert('Failed to create alert. Please enter a valid contract.');
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="card p-6 space-y-4"
      >
        <div className="flex items-center space-x-2">
          <CalendarDaysIcon className="h-6 w-6 mr-2" />
          <h2 className="text-xl text-base-bold">
            Create New {contractTypeValue.slice(0, 1).toUpperCase()}
            {contractTypeValue.slice(1)} Alert
          </h2>
        </div>

        {/* <div className="w-full">
          <label htmlFor="contractType" className="block mb-2">
            Contract Type
          </label>
          <Select
            options={watchTypeOptions}
            {...register("type", { required: true })}
            onChange={(e) => {
              contractType.current = e.target.value;
              setTrigger(trigger + 1);
            }}
          />
        </div> */}

        <div className="flex flex-col gap-4">
          {contractTypeValue === 'spread' ? (
            <>
              <div className="grid grid-cols-3 gap-x-2 w-full flex">
                <div className="w-full">
                  <label htmlFor="crop" className="block mb-2">
                    Commodity
                  </label>
                  <Select
                    name="crop"
                    options={commodityOptions}
                    onChange={(e) => {
                      commodity.current = e.target.value;
                      setTrigger(trigger + 1);
                    }}
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="month1" className="block mb-2">
                    Month 1
                  </label>
                  <Select
                    name="month1"
                    defaultValue="december"
                    options={
                      cropToMonthOptions[
                        commodity.current as keyof typeof cropToMonthOptions
                      ]
                    }
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="year1" className="block mb-2">
                    Year 1
                  </label>
                  <Select
                    name="year1"
                    defaultValue="2024"
                    options={yearOptions}
                  />
                </div>
              </div>
              <div className="grid gap-x-2 grid-cols-3 w-full flex">
                <div className="w-full">
                  <label htmlFor="month2" className="block mb-2">
                    Month 2
                  </label>
                  <Select
                    name="month2"
                    options={
                      cropToMonthOptions[
                        commodity.current as keyof typeof cropToMonthOptions
                      ]
                    }
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="year2" className="block mb-2">
                    Year 2
                  </label>
                  <Select
                    name="year2"
                    defaultValue="2024"
                    options={yearOptions}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="grid grid-cols-3 gap-x-2 w-full flex">
              <div className="w-full">
                <label htmlFor="crop" className="block mb-2">
                  Commodity
                </label>
                <Select
                  name="crop"
                  options={commodityOptions}
                  onChange={(e) => {
                    commodity.current = e.target.value;
                    setTrigger(trigger + 1);
                  }}
                />
              </div>
              <div className="w-full">
                <label htmlFor="month" className="block mb-2">
                  Month
                </label>
                <Select
                  name="month"
                  defaultValue="december"
                  options={
                    cropToMonthOptions[
                      commodity.current as keyof typeof cropToMonthOptions
                    ]
                  }
                />
              </div>
              <div className="w-full">
                <label htmlFor="year" className="block mb-2">
                  Year
                </label>
                <Select name="year" defaultValue="2024" options={yearOptions} />
              </div>
            </div>
          )}

          {contractTypeValue === 'options' && (
            <div>
              <div className="w-full mb-2">
                <label htmlFor="optionType" className="block mb-2">
                  Call/Put
                </label>
                <Select
                  name="optionType"
                  options={[
                    { value: 'call', label: 'Call' },
                    { value: 'put', label: 'Put' },
                  ]}
                />
              </div>
              <div className="w-full">
                <label htmlFor="strikePrice" className="block mb-2">
                  Strike Price
                </label>
                {strikePrices &&
                  strikePrices?.data &&
                  strikePrices?.data.strikePrices[
                    optionType.slice(0, 1).toUpperCase() +
                      optionType.slice(1, optionType.length)
                  ] &&
                  strikePrices?.data.strikePrices[
                    optionType.slice(0, 1).toUpperCase() +
                      optionType.slice(1, optionType.length)
                  ].length > 0 && (
                    <Select
                      name="strikePrice"
                      options={
                        strikePrices.data?.strikePrices[
                          optionType.slice(0, 1).toUpperCase() +
                            optionType.slice(1, optionType.length)
                        ]!
                      }
                    />
                  )}
              </div>
            </div>
          )}

          {contractTypeValue === 'basis' && (
            <div className="gap-x-2 flex flex-col gap-4">
              <div className="w-full">
                <label htmlFor="basisLocation" className="block mb-2">
                  Company & Location
                </label>
                <Select name="basisLocation" value={selectedBasisLocationCompnay} onChange={(e) => {
                  setSelectedBasisLocationCompany(e.target.value);
                }} options={companyLocationOptions} />
              </div>
              <div className="w-full">
                <label htmlFor="basisValue" className="block mb-2">
                  Basis Value
                </label>
                <TextInput name="basisValue" placeholder="Enter basis value" />
              </div>
            </div>
          )}

          <div className="grid grid-cols-3 w-full flex gap-x-2">
            <div>
              <label htmlFor="timing" className="block mb-2">
                Timing
              </label>
              <Select
                name="timing"
                options={[
                  { value: 'below', label: 'Below' },
                  { value: 'above', label: 'Above' },
                  { value: 'at', label: 'At' },
                ]}
              />
            </div>
            <div>
              <label htmlFor="triggerPoint" className="block mb-2">
                Trigger Point
              </label>
              <Select
                name="triggerPoint"
                options={[{ value: 'last', label: 'Last' }]}
              />
            </div>
            <div className="w-full">
              <label htmlFor="price" className="block mb-2">
                Price
              </label>
              <TextInput name="price" placeholder="Enter price" />
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <span>Notify via:</span>
          <label className="flex items-center space-x-2">
            <input
              type="radio"
              value="text"
              {...register('alertVia', { required: true })}
              defaultChecked
              className="custom-radio"
            />
            <span>Text</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="radio"
              value="email"
              {...register('alertVia', { required: true })}
              className="custom-radio"
            />
            <span>Email</span>
          </label>
        </div>

        <div className="flex space-x-4">
          <Button
            onClick={() => setIsCreatingNewAlert(false)}
            type="button"
            className={`bg-[${ThemeColors.lightGray}]`}
          >
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Apply
          </Button>
        </div>
      </form>

      {alerts.length > 0 && (
        <div className="mt-6">
          <h3 className="text-lg text-base-semibold">Current Alerts</h3>
          <ul>
            {alerts.map((alert) => (
              <li key={alert.id} className="flex justify-between p-2 border-b">
                <span>
                  {alert.crop} - {alert.condition} - {alert.price}
                </span>
                <span>{alert.status}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </FormProvider>
  );
};

export default CreateAlert;
