import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
  ArrowsPointingOutIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid';
import React, { useMemo } from 'react';
import Table, { TableConfigs } from '../../../components/commons/table/Table';
import {
  useHistoricalInsights,
  useTableData,
} from '../../../hooks/historical-insights/use-historical-insights';
import { useControlledUser, useUser } from '../../../contexts/auth-context';
import { capitalizeFirst } from '../../../utils';
import TableLayout from '../../../components/dashboard/commons/TableLayout';

function TableData() {
  const user = useControlledUser();
  const {
    year,
    relationship,
    crop,
    page,
    setPage,
    setNewCenter,
    rgb,
    setRgb,
    pageSize,
    tableChartRef,
  } = useHistoricalInsights();
  const tableData = useTableData(user?.id || '', crop, relationship, year);

  const formattedTableData = useMemo(() => {
    return (tableData?.data ?? [])
      .map((data: any) => {
        return {
          ...data,
          color: { r: data.x_r, g: data.x_g, b: data.x_b },
          yield: parseFloat(data.yield).toFixed(2),
          yield_performance: parseFloat(data.yield_performance).toFixed(2),
        };
      });
  }, [tableData.data, page, pageSize]);

  const historicalInsightsYieldConfigs = (
    relationship: string
  ): TableConfigs => {
    return {
      cols: [
        {
          key: 'x_bin',
          name: capitalizeFirst(relationship),
        },
        {
          key: 'color',
          name: 'Map Key',
          type: 'element',
          element: (item) => (
            <div
              onClick={() => {
                setNewCenter([parseFloat(item.lon), parseFloat(item.lat)]);
                setRgb([
                  parseInt(item.x_r),
                  parseInt(item.x_g),
                  parseInt(item.x_b),
                ]);
              }}
              style={{
                backgroundColor: `rgb(${item.x_r}, ${item.x_g}, ${item.x_b})`,
              }}
              className={`w-full h-4`}
            />
          ),
        },
        {
          key: 'yield',
          name: 'Yield',
        },
        {
          key: 'yield_performance',
          name: 'Yield Performance',
        },
      ],
    };
  };

  return (
    <div ref={tableChartRef} className="card overflow-hidden">
      <TableLayout
        title={
          <div className="flex items-center justify-between w-full">
            <p className="display-sm-bold cardHeader">
              All {capitalizeFirst(crop)} Fields
            </p>
            <InformationCircleIcon className="size-6 fill-yellow" />
          </div>
        }
      >
        {!(rgb[0] === 0 && rgb[1] === 0 && rgb[2] === 0) && (
          <div
            className="card ml-auto mr-auto cursor-pointer max-w-max flex gap-2 px-4 py-2 rounded-md"
            onClick={() => setRgb([0, 0, 0])}
          >
            <ArrowsPointingOutIcon className="w-6 h-6 cursor-pointer" />
            <p>Show All</p>
          </div>
        )}

        <Table
          configs={historicalInsightsYieldConfigs(relationship)}
          loading={false}
          data={formattedTableData ? formattedTableData : []}
          pagination={{ size: pageSize }}
        />
      </TableLayout>
    </div>
  );
}

export default TableData;
