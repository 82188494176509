import { useEffect } from 'react';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useWebsocketContext } from '../../../../contexts/websocket-context';
import {
  useDeletePrescriptionHistory,
  useGetFieldPrescriptionHistory,
} from '../../../../hooks/field/use-field-prescription';
import { useWebSocketDataV2 } from '../../../../hooks/websocket/use-websocket-data';
import { PrescriptionHistoryItem } from '../../../../types/history';
import Button from '../../../commons/Button';
import Table, { RowActions, TableConfigs } from '../../../commons/table/Table';

export default function Logs({ field }: { field: string }) {
  const year = useYearFilter();
  // const { setTables } = useWebsocketContext();
  // setTables(['Prescription_History']);
  // const wsMsg = useWebSocketDataV2('Prescription');
  const { data, isFetching } = useGetFieldPrescriptionHistory({
    year: year ?? '',
    field,
  });
  const { mutate: deleteHistory, isPending: isDeleting } =
    useDeletePrescriptionHistory();

  // useEffect(() => {
  //   console.log('wsMsg===', wsMsg)
  // }, [wsMsg])

  const tableConfigs: TableConfigs = {
    cols: [
      {
        name: 'Timestamp (UTC)',
        key: 'date_time',
      },
      {
        name: 'Changed by',
        key: 'user_name',
      },
      {
        name: 'Zone',
        key: 'agrivar_zone',
      },
      {
        name: 'Adjusted Item',
        key: 'key',
      },
      {
        name: 'Set Value',
        key: 'value',
      },
      {
        name: 'Recommended Value',
        key: 'recommended_value',
      },
      {
        name: 'Status',
        key: 'status',
      },
    ],
  };

  const tableActions = (
    <>
      <Button size="sm" color="primary">
        Download
      </Button>
    </>
  );

  // const rowActions: RowActions = {
  //   delete(item: PrescriptionHistoryItem) {
  //     deleteHistory({
  //       year: item.year,
  //       field: item.field,
  //     });
  //   },
  // };

  return (
    <Table
      configs={tableConfigs}
      data={data}
      tableActions={tableActions}
      // rowActions={rowActions}
      loading={isFetching || isDeleting}
      pagination={{
        size: 10,
      }}
    />
  );
}
