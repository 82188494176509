import React, { useEffect, useMemo, useState } from 'react';
import { capitalizeFirst, formatXBin } from '../../../utils';
import { FieldMapItem, MapItem, ZoneMapItem } from '../../../types';
import { useParams } from 'react-router-dom';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useTableData } from '../../../hooks/map/use-map';
import { useControlledUser, useUser } from '../../../contexts/auth-context';
import { useMapContext } from '../../../contexts/map-context';
import { cropColors, mapParamsToTitles } from '../../../constants';
import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid';
import Select from '../../../components/commons/Select';
import { uniq } from 'lodash';

function MapSelections() {
  const { type: typeParam = '' } = useParams();
  const user = useControlledUser();

  const {
    selectedField,
    setSelectedField,
    selectedField: selectedMapField,
    selectedArea,
    selectedDate,
    selectedChart,
    setSelectedChart,
    setSelectedArea,
    selectedFieldIndex,
    setSelectedFieldIndex,
    setSelectedChartCrop,
    selectedChartCrop,
    selectedZone,
    setSelectedZone,
  } = useMapContext();

  const type = typeParam === 'hiResolutionSatellite' ? 'revenue' : typeParam;
  const year = useYearFilter();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const itemsPerPage = 7;
  const cropTableData = useTableData(
    user?.id || '',
    selectedArea,
    type!,
    'crop',
    year!,
    selectedDate,
    'crop'
  );

  const sortedCropTableData = useMemo(() => {
    return [...(cropTableData?.data || [])].sort(
      (a, b) => Number(a.x_index) - Number(b.x_index)
    );
  }, [cropTableData?.data]);

  const infoTab = {
    yield_probability:
      'This chart represents the probability of hitting the yield goal(s) set in the AgriVaR prescriptions, and the number of acres in each probability range.',
    yield_performance:
      'Yield performance is the difference between predicted yield (or actual yield when actual yield data is available for the year) and the yield goal set in the AgriVaR prescriptions, in bushels per acre.',
    yield_error:
      'Yield error is the actual yield minus the predicted yield. This quantiifes the accuracy of the yield predictions at some date. Data will only display once actual yield data is available for the year. This error is measured in bushels per acre.',
    sigma:
      'Risk represents the standard deviation of the yield prediction / error. This risk is measured in bushels per acre.',
  };

  const cropLabels: string[] = useMemo(() => {
    const newCropLabels = Array.from(
      new Set(
        sortedCropTableData.map(
          (dataItem: MapItem) => formatXBin(dataItem.x_bin, typeParam) as string
        )
      )
    ) as string[];
    return newCropLabels;
  }, [sortedCropTableData]);

  const crops = useMemo(() => {
    if (!sortedCropTableData) return {};
    const cropData: { [key: string]: number[] } = {};
    sortedCropTableData.forEach((dataItem: MapItem) => {
      let area = parseFloat(dataItem.area);
      if (isNaN(area)) area = 0;
      if (!cropData[dataItem.crop]) {
        cropData[dataItem.crop] = Array(cropLabels?.length ?? 0).fill(0);
      }
      const index = cropLabels?.indexOf(
        formatXBin(dataItem.x_bin, typeParam) as string
      );
      if (index !== -1) {
        cropData[dataItem.crop][index] = area;
      }
    });
    if (
      Object.keys(cropData).length > 0 &&
      !Object.keys(cropData).includes(selectedArea)
    ) {
      setSelectedArea(capitalizeFirst(Object.keys(cropData)[0]));
    }
    return cropData;
  }, [sortedCropTableData, cropLabels]);

  const [uniqueCrops, setUniqueCrops] = useState<string[]>([]);
  const [loadedUniqueCrops, setLoadedUniqueCrops] = useState<boolean>(false);

  useEffect(() => {
    if (!cropTableData?.data || loadedUniqueCrops) return;
    setUniqueCrops(Object.keys(crops));
  }, [crops]);

  // Paginated crops based on current page
  const paginatedCrops = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return uniqueCrops.slice(startIndex, startIndex + itemsPerPage);
  }, [uniqueCrops, currentPage]);

  // Set the first crop as the default selected crop

  ///////////////////////
  // Fields Selections //
  ///////////////////////
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [fieldIndexMap, setFieldIndexMap] = useState<{ [key: string]: string }>(
    {}
  );
  const fieldTableData = useTableData(
    user?.id || '',
    selectedArea,
    type!,
    'field',
    year!,
    selectedDate,
    'field'
  );
  const fieldLabels: string[] = useMemo(
    () =>
      Array.from(
        new Set(
          fieldTableData?.data?.map((dataItem: FieldMapItem) =>
            formatXBin(dataItem.x_bin, typeParam)
          )
        )
      ) as string[],
    [fieldTableData?.data]
  );
  const fields = useMemo(() => {
    if (!fieldTableData?.data) return {};

    const fieldData: { [key: string]: { data: number[]; crop: string } } = {};
    const tmpFieldIndexMap: { [key: string]: string } = {};
    fieldTableData.data.forEach((dataItem: FieldMapItem) => {
      tmpFieldIndexMap[dataItem.field] = dataItem.field_index;
      let area = parseFloat(dataItem.area);
      if (isNaN(area)) area = 0;

      if (!fieldData[dataItem.field]) {
        fieldData[dataItem.field] = {
          data: Array(cropLabels?.length ?? 0).fill(0),
          crop: dataItem.crop,
        };
      }

      const index = fieldLabels?.indexOf(formatXBin(dataItem.x_bin, typeParam));
      if (index !== -1) {
        fieldData[dataItem.field].data[index] = area;
      }
    });

    setFieldIndexMap(tmpFieldIndexMap);

    return fieldData;
  }, [fieldTableData?.data, fieldLabels]);

  const [uniqueFields, setUniqueFields] = useState<string[]>([]);
  const [loadedFields, setLoadedFields] = useState<boolean>(false);

  useEffect(() => {
    if (!loadedFields && Object.keys(fields).length > 0) {
      setUniqueFields(
        Object.keys(fields).sort((a, b) => {
          const cropA = fields[a].crop || 'Unknown Crop';
          const cropB = fields[b].crop || 'Unknown Crop';
          const comp = cropA.localeCompare(cropB);
          // if the crops are the same, sort by field name
          if (comp === 0) return a.localeCompare(b);
          return comp;
        })
      );
      setZoneField(Object.keys(fields)[0]);
      setLoadedFields(true);
    }
  }, [fields]);

  const filteredFields = useMemo(() => {
    return uniqueFields.filter((fieldName) =>
      fieldName?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
  }, [uniqueFields, searchTerm]);

  const paginatedFields = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredFields.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredFields, currentPage]);

  //////////////////////
  // Zones Selections //
  //////////////////////
  const zoneTableData = useTableData(
    user?.id || '',
    selectedArea,
    type!,
    'agrivar_zone2',
    year!,
    selectedDate,
    'zone'
  );

  const zoneLabels: string[] = useMemo(
    () =>
      Array.from(
        new Set(
          zoneTableData?.data?.map((dataItem: ZoneMapItem) => {
            if (dataItem.x_bin === 'nan') {
              return;
            }
            return dataItem.x_bin;
          })
        )
      ) as string[],
    [zoneTableData?.data]
  );

  const zones = useMemo(() => {
    if (!zoneTableData?.data) return {};

    const zoneData: {
      [key: string]: { data: number[]; crop: string; field: string };
    } = {};

    zoneTableData.data.forEach((dataItem: ZoneMapItem) => {
      let area = parseFloat(dataItem.area);
      if (isNaN(area)) area = 0;

      if (!zoneData[dataItem.agrivar_zone2]) {
        zoneData[dataItem.agrivar_zone2] = {
          data: Array(zoneLabels?.length ?? 0).fill(0),
          crop: dataItem.crop,
          field: dataItem.field,
        };
      }

      const index = zoneLabels?.indexOf(formatXBin(dataItem.x_bin, typeParam));
      if (index !== -1) {
        zoneData[dataItem.agrivar_zone2].data[index] = area;
      }
    });

    return zoneData;
  }, [zoneTableData?.data, zoneLabels]);

  // Get unique zone names from the data
  const uniqueZones = useMemo(
    () =>
      Object.keys(zones).sort((a, b) => {
        const zoneA = zones[a];
        const zoneB = zones[b];
        if (!zoneA || !zoneB) return 0;
        const comp = zoneA.crop.localeCompare(zoneB.crop);
        // if the crops are the same, sort by zone name
        if (comp === 0) return a.localeCompare(b);
        return comp;
      }),
    [zones]
  );

  const [zoneField, setZoneField] = useState<string>('');

  const filteredZones = useMemo(() => {
    // return zones which are part of the zonefield
    return uniqueZones.filter(
      (zoneName) => zones[zoneName].field === zoneField
    );
  }, [uniqueZones, zoneField]);

  const paginatedZones = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredZones.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredZones, currentPage]);

  return (
    <div>
      <div className="w-[100%] flex px-4 flex-col py-2 ">
        <div className="flex gap-2">
          <div className="relative group">
            <InformationCircleIcon className="w-8 h-8 text-yellow cursor-pointer" />
            {Object.keys(infoTab).includes(typeParam) && (
              <div className="absolute z-[99] bottom-full mb-2 w-max max-w-xs p-2 text-sm bg-gray-800 text-white rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out pointer-events-none">
                {infoTab[typeParam as keyof typeof infoTab]}
              </div>
            )}
          </div>

          <p className="display-sm-bold">
            Expected{' '}
            {
              mapParamsToTitles[
                (typeParam as keyof typeof mapParamsToTitles) || 'revenue'
              ]
            }{' '}
            per Acre By {selectedChart === 'crop' && `Crop`}
            {selectedChart === 'field' && `Field`}
            {selectedChart === 'zone' && `Zone`}
          </p>
        </div>
        <div className="flex items-center mt-4 mb-4">
          <input
            type="radio"
            value="text"
            name="display"
            defaultChecked={selectedChart === 'crop'}
            className="custom-radio"
            onChange={() => {
              setSelectedChart('crop');
              setSelectedZone('');
              setSelectedField('');
              setSelectedArea('Corn');
              setSelectedChartCrop('Corn');
            }}
          />
          <span className="mr-4 ml-2">Crop</span>
          <input
            type="radio"
            value="text"
            name="display"
            defaultChecked={selectedChart === 'field'}
            className="custom-radio"
            onChange={() => {
              if (zoneField) {
                setSelectedField(zoneField);
                setSelectedFieldIndex(fieldIndexMap[zoneField]);
                setSelectedArea(fields[zoneField]?.crop || 'Corn');
              } else {
                setSelectedField(uniqueFields[0]);
                setSelectedFieldIndex(fieldIndexMap[uniqueFields[0]]);
                setSelectedArea(fields[uniqueFields[0]]?.crop || 'Corn');
              }
              setSearchTerm('');
              setSelectedArea('all');
              setSelectedChart('field');
              setSelectedZone('');
            }}
          />
          <span className="mr-4 ml-2">Field</span>
          <input
            type="radio"
            value="text"
            name="display"
            defaultChecked={selectedChart === 'zone'}
            className="custom-radio"
            onChange={() => {
              setSelectedChart('zone');
              setSelectedArea('all');
              setZoneField(selectedField);
              // set selected zone to first zone in the list from field
              setSelectedZone(selectedField + ' Zone: 1');
              setSelectedField('');
            }}
          />
          <span className="mr-4 ml-2">Zone</span>
        </div>
      </div>
      {selectedChart === 'crop' && (
        <div className=" flex flex-col justify-between">
          <div className="mt-0 flex flex-col gap-4 px-4">
            <div className="flex gap-4">
              {paginatedCrops &&
                paginatedCrops.length > 0 &&
                paginatedCrops.map((cropName) => (
                  <div
                    key={cropName}
                    className={`max-w-max cursor-pointer max-h-max px-4 flex py-2 rounded-md`}
                    onClick={() => {
                      setSelectedChartCrop(cropName);
                      setSelectedArea(cropName);
                      setSelectedField('');
                    }}
                    style={{
                      backgroundColor:
                        capitalizeFirst(selectedChartCrop) === cropName
                          ? cropColors[cropName] || 'rgba(255, 99, 132, 0.2)'
                          : 'transparent',
                      borderWidth:
                        selectedChartCrop === cropName ? '0px' : '2px',
                      borderColor:
                        cropColors[cropName] || 'rgba(255, 99, 132, 0.2)',
                    }}
                  >
                    {cropName}
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
      {selectedChart === 'field' && (
        <div className="flex px-4 flex-col justify-between">
          <div className=" flex gap-4 w-full flex-wrap">
            {/* Search Input */}
            <input
              type="text"
              placeholder="Search fields..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-2 text-black border rounded"
            />
            {paginatedFields.map((fieldName) => (
              <div
                key={fieldName}
                className={`cursor-pointer px-4 py-2 flex items-center rounded-md ${fieldName === selectedField ? 'bg-gray-200' : 'bg-gray-100'}`}
                onClick={() => {
                  setSelectedField(fieldName);
                  setSelectedFieldIndex(fieldIndexMap[fieldName]);
                  setSelectedArea(fields[fieldName]?.crop || 'Corn');
                }}
                style={{
                  backgroundColor:
                    capitalizeFirst(selectedField) === fieldName
                      ? cropColors[fields[fieldName]?.crop] ||
                        'rgba(255, 99, 132, 0.2)'
                      : 'transparent',
                  borderWidth: selectedField === fieldName ? '0px' : '2px',
                  borderColor:
                    cropColors[fields[fieldName]?.crop] ||
                    'rgba(255, 99, 132, 0.2)',
                }}
              >
                {fieldName}
              </div>
            ))}
          </div>
          {/* Pagination Controls */}
          <div className="flex items-center justify-between mt-4">
            <div
              onClick={() => {
                if (currentPage === 1) {
                  return;
                }
                setCurrentPage((prev) => Math.max(prev - 1, 1));
              }}
            >
              <ArrowLeftCircleIcon className="w-8 text-yellow h-8 cursor-pointer" />
            </div>
            <p>
              {currentPage}/{Math.ceil(filteredFields.length / itemsPerPage)}
            </p>
            <div
              onClick={() => {
                if (currentPage * itemsPerPage >= filteredFields.length) {
                  return;
                }
                setCurrentPage((prev) => prev + 1);
              }}
            >
              <ArrowRightCircleIcon className="w-8 text-yellow h-8 cursor-pointer" />
            </div>
          </div>
        </div>
      )}
      {selectedChart === 'zone' && (
        <div className="flex px-4 flex-col justify-between">
          <div className="flex items-center gap-4 w-full">
            {/* Search Input */}
            <p className="max-w-max w-full">Zones By Field:</p>
            <Select
              defaultValue={zoneField}
              name="zoneFieldSelection"
              options={uniqueFields.map((field) => ({
                label: field,
                value: field,
              }))}
              value={zoneField}
              onChange={(e) => {
                setZoneField(e.target.value);
                const newZone = e.target.value + ' Zone: 1';
                setSelectedZone(newZone);
                setCurrentPage(1);
              }}
            />
          </div>
          <div className="gap-4 mt-4 flex flex-wrap">
            {paginatedZones.map((zoneName) => (
              <div
                key={zoneName}
                className={`cursor-pointer max-w-maxcursor-pointer px-4 flex items-center py-2 rounded-md ${zoneName === selectedZone ? 'bg-gray-200' : 'bg-gray-100'}`}
                onClick={() => {
                  setSelectedZone(zoneName);
                  setSelectedArea(zones[zoneName]?.crop || 'all');
                  setSelectedFieldIndex(fieldIndexMap[zones[zoneName]?.field]);
                }}
                style={{
                  backgroundColor:
                    selectedZone === zoneName
                      ? cropColors[zones[selectedZone]?.crop] ||
                        'rgba(255, 99, 132, 0.2)'
                      : 'transparent',
                  borderWidth: selectedZone === zoneName ? '0px' : '2px',
                  borderColor:
                    cropColors[zones[selectedZone]?.crop] ||
                    'rgba(255, 99, 132, 0.2)',
                }}
              >
                <p>{zoneName}</p>
              </div>
            ))}
          </div>
          {/* Pagination Controls */}
          <div className="flex items-center justify-between mt-4">
            <div
              onClick={() => {
                if (currentPage === 1) {
                  return;
                }
                setCurrentPage((prev) => Math.max(prev - 1, 1));
              }}
            >
              <ArrowLeftCircleIcon className="w-8 text-yellow h-8 cursor-pointer" />
            </div>
            <p>
              {currentPage}/{Math.ceil(filteredZones.length / itemsPerPage)}
            </p>
            <div
              onClick={() => {
                if (currentPage * itemsPerPage >= filteredZones.length) {
                  return;
                }
                setCurrentPage((prev) => prev + 1);
              }}
            >
              <ArrowRightCircleIcon className="w-8 text-yellow h-8 cursor-pointer" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MapSelections;
