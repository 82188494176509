import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createLeaseApi,
  deleteLeaseApi,
  getLeasesApi,
  updateLeaseApi,
} from '../../api/risk-api';
import {
  CreateLeasePayload,
  LeaseItem,
  UpdateLeasePayload,
} from '../../types/lease';
import { enqueueSnackbar } from 'notistack';

export const useLeases = (userId: string, year: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['risk/leases', userId, year],
    queryFn: () => getLeasesApi(userId, year),
    enabled: !!userId && !!year,
  });

  return { data: data?.data as LeaseItem[], ...rest };
};

export const useCreateLease = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['risk/leases/create'],
    mutationFn: (payload: CreateLeasePayload) => createLeaseApi(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['risk/leases'] });
    },
  });
};

export const useUpdateLease = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['risk/leases/update'],
    mutationFn: (payload: UpdateLeasePayload) => updateLeaseApi(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['risk/leases'] });
    },
  });
};

export const useDeleteLease = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['risk/leases/delete'],
    mutationFn: ({
      userId,
      year,
      leaseId,
    }: {
      userId: string;
      year: string;
      leaseId: string;
    }) => deleteLeaseApi({ userId, year, leaseId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['risk/leases'] });
      enqueueSnackbar('Lease deleted successfully', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('Failed to delete lease', { variant: 'error' });
    },
  });
};
