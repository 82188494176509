import {
  useIncomeViewFilter,
  useYearFilter,
} from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import {
  getTotalRevenue14DaysChangeChartData,
  useFarmRevenues14Change,
} from '../../../hooks/farm/use-farm-revenues-14-change';
import { lineChartColorsByNetwork } from '../../../utils';
import SingleLineChart from '../../commons/charts/SingleLineChart';

function ExpectedRevenue14ChangeChart({
  width,
  height,
}: {
  width: number;
  height: number;
}) {
  const user = useUser();
  const year = useYearFilter();
  const incomeView = useIncomeViewFilter();
  const { data } = useFarmRevenues14Change(user?.id || '', year || '');

  const { netIncome, revenue, tradeDates } =
    getTotalRevenue14DaysChangeChartData(data);

  const dataToView = incomeView === 'revenue' ? revenue : netIncome;

  return (
    <div>
      <SingleLineChart
        data={dataToView}
        labels={tradeDates}
        width={width}
        height={height}
        stepSize={1000}
        scales={{
          x: {
            display: false,
          },
        }}
        backgroundColor={lineChartColorsByNetwork(user?.network_partner)}
        borderColor={
          lineChartColorsByNetwork(user?.network_partner)?.borderColor
        }
        scalesColor={lineChartColorsByNetwork(user?.network_partner)?.scalesColor}
      />
    </div>
  );
}

export default ExpectedRevenue14ChangeChart;
