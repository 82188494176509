import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { nanoid } from 'nanoid';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  ContractOptions,
  LessorOptions,
  MonthOptions,
} from '../../../../constants/risk-management';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useUser } from '../../../../contexts/auth-context';
import { useFieldPolygonsByYear } from '../../../../hooks/field/use-field-polygons';
import { useCreateLease } from '../../../../hooks/lease/use-lease';
import { getYearOptions } from '../../../../utils';
import Button from '../../../commons/Button';
import CardHeader from '../../../commons/CardHeader';
import Select, { AwaitSelect } from '../../../commons/Select';
import ComboDatePicker from '../../../commons/inputs/ComboDatePicker';
import TextInput from '../../../commons/inputs/TextInput';

export default function AddLeaseForm({
  onCancel,
  type,
}: {
  type: string;
  onCancel: () => void;
}) {
  const user = useUser();
  const year = useYearFilter();

  const form = useForm({
    defaultValues: {
      unique_lease_id: nanoid(),
      lease_type: type,
      user_id: user?.id,
      year: year,
      valuation: '',
      tied_futures_month_year: '',
    } as any,
  });

  // Field options
  const { data: allFields, isFetching: isFetchingFields } =
    useFieldPolygonsByYear(user?.id ?? '', year ?? '');
  const fieldOptions = useMemo(() => {
    return allFields?.map((field) => ({
      label: field.field,
      value: `${field.field}-${field.field_index}`,
    }));
  }, [allFields]);

  // set field_applied and field_index
  const fieldApplied = form.watch('field_applied');
  useEffect(() => {
    if (fieldApplied) {
      const [field, field_index] = fieldApplied.split('-');
      if (field) {
        form.setValue('field_applied', field);
      }
      if (field_index) {
        form.setValue('field_index', field_index);
      }
    }
  }, [fieldApplied]);

  // set tied_futures_month_year
  const leaseMonth = form.watch('leaseMonth');
  const leaseYear = form.watch('leaseYear');
  useEffect(() => {
    if (leaseMonth && leaseYear) {
      form.setValue('tied_futures_month_year', `${leaseMonth} '${leaseYear}`);
    }
  }, [leaseMonth, leaseYear]);

  const { mutateAsync: createLease, isPending } = useCreateLease();
  const handleSubmit = async ({ leaseYear, leaseMonth, ...values }: any) => {
    try {
      await createLease(values);
      enqueueSnackbar(`Successfully added ${type} Lease`, {
        variant: 'success',
      });
      form.reset();
      onCancel();
    } catch (error) {
      enqueueSnackbar(`Failed to add ${type} Lease`, { variant: 'error' });
    }
  };

  return (
    <div>
      <CardHeader
        title={`Add ${type} Lease`}
        className="mb-8"
        icon={<PlusCircleIcon className="size-8" />}
      />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
          <Select
            name="lessee_or_lessor"
            label="Lessee / Lessor"
            options={LessorOptions}
            required
          />
          <TextInput name="lease_name" label="Lease Name" required />
          <TextInput name="acres_leased" label="Acres" type="number" required />
          <TextInput
            name="share_of_production_pct"
            label="Production Share"
            type="number"
            required
          />
          <TextInput
            name="share_of_revenue_pct"
            label="Revenue Share"
            type="number"
            endAdornment={'%'}
            required
          />
          <TextInput
            name="base_rent_per_acre"
            label="Rent / Acre"
            type="number"
            endAdornment={'$'}
            required
          />
          <ComboDatePicker name="date_pymt" label="Payment Date" required />
          {type === 'Flex' && (
            <>
              <div className="flex items-end gap-2">
                <Select
                  name="leaseMonth"
                  options={MonthOptions}
                  label="Month/Year"
                  required
                />
                <Select
                  name="leaseYear"
                  label="Year"
                  hiddenLabel
                  options={getYearOptions(10, 5, false)}
                  required
                />
              </div>
              <Select
                name="exchange_commodity"
                label="Exchange/Commodity"
                options={ContractOptions}
                required
              />
              {form.watch('exchange_commodity') === 'Other' && (
                <TextInput
                  name="exchange_commodity_other"
                  label="Other"
                  required
                />
              )}
            </>
          )}
          <AwaitSelect
            name="field_applied"
            label="Field Applied"
            options={[
              { label: 'None', value: 'None' },
              ...(fieldOptions ?? []),
            ]}
            required
            isLoading={isFetchingFields}
          />

          <div className="flex justify-between space-x-3">
            <Button className="w-full" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className="w-full"
              color="primary"
              type="submit"
              disabled={!form.formState.isValid || isPending}
              loading={isPending}
            >
              Apply
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
