import moment from 'moment';
import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useControlledUser, useUser } from '../../../contexts/auth-context';
import CardHeader from '../../../components/commons/CardHeader';
import { CropColors, mapParamsToTitles, tabToUnit } from '../../../constants';
import { ChartOptions, ChartData, Plugin } from 'chart.js';
import { useMapContext } from '../../../contexts/map-context';
import { useLineChartData } from '../../../hooks/map/use-map';
import { capitalizeFirst, getCropColors } from '../../../utils';
import { useDateSet } from './useDateSet';
import { useS3Keys } from './useS3Keys';
import useTypeParams from './useTypeParams';

function RelationshipLineChart({
  date,
  crop,
  relationship,
}: {
  date: string;
  crop: string;
  relationship: string;
}) {
  const user = useControlledUser();
  const year = useYearFilter();
  const typeParam = useTypeParams();
  const { selectedFieldIndex, selectedZone, selectedChart, selectedField } =
    useMapContext();

  const { s3Keys, loading: s3KeysLoading } = useS3Keys({
    userId: user?.id || '',
    year: year || '',
    type: typeParam,
  });

  const { dateSet } = useDateSet(s3Keys);

  const cropMap = {
    all: 'farm',
    corn: 'corn',
    soybeans: 'soybeans',
    wheat: 'wheat',
  };

  const titleCropMap = {
    all: 'whole farm',
    corn: 'corn',
    soybeans: 'soybeans',
    wheat: 'wheat',
  };

  const { data: lineChartData } = useLineChartData(
    user?.id ?? '',
    year!,
    cropMap[(crop?.toLowerCase() as keyof typeof cropMap) ?? 'corn'],
    selectedChart,
    selectedFieldIndex,
    typeParam,
    selectedZone
      ? selectedZone.split(' ')[selectedZone.split(' ').length - 1]
      : ''
  );

  const chartData: ChartData<'line'> = useMemo(() => {
    if (!lineChartData)
      return {
        labels: [],
        datasets: [],
      };

    const cutoffDate = moment('2024-06-01'); // Define the cutoff date

    const labels = lineChartData
      .filter((data: any) => moment(data.Date).isSameOrAfter(cutoffDate)) // Filter data after 2024-06-01
      .map((data: any) => data.Date);

    const values = lineChartData
      .filter((data: any) => {
        return (
          moment(data.Date).isSameOrAfter(cutoffDate) &&
          dateSet.includes(data.Date)
        );
      }) // Filter data after 2024-06-01
      .map((data: any) => ({
        y: data['value'],
        x: data.Date,
      }));

    const values1 = lineChartData
      .filter(
        (data: any) =>
          moment(data.Date).isSameOrAfter(cutoffDate) &&
          data['value_0.1'] !== 'nan'
      ) // Filter data after 2024-06-01
      .map((data: any) => ({
        y: data['value_0.1'],
        x: data.Date,
      }));

    const labels1 = values1.map((data: any) => data.Date);

    const values5 = lineChartData
      .filter(
        (data: any) =>
          moment(data.Date).isSameOrAfter(cutoffDate) &&
          data['value_0.5'] !== 'nan'
      ) // Filter data after 2024-06-01
      .map((data: any) => ({
        y: data['value_0.5'],
        x: data.Date,
      }));
    const labels5 = values5.map((data: any) => data.Date);

    const values9 = lineChartData
      .filter(
        (data: any) =>
          moment(data.Date).isSameOrAfter(cutoffDate) &&
          data['value_0.9'] !== 'nan'
      ) // Filter data after 2024-06-01
      .map((data: any) => ({
        y: data['value_0.9'],
        x: data.Date,
      }));
    const labels9 = values9.map((data: any) => data.Date);

    const cropName = capitalizeFirst(
      cropMap[
        (crop?.toLowerCase() as keyof typeof cropMap) ?? 'corn'
      ] as keyof typeof CropColors
    );

    return {
      labels,
      datasets: [
        {
          label: labels,
          data: values,
          borderColor: getCropColors(cropName, user?.network_partner),
          borderWidth: 2,
          pointRadius: 0, // Disable points
        },
        {
          label: labels1,
          data: values1,
          borderColor: getCropColors(cropName, user?.network_partner),
          backgroundColor: 'rgba(174, 155, 126, .5)',
          borderWidth: 2,
          borderDash: [5, 5],
          fill: '+1',
          pointRadius: 0, // Disable points
        },
        {
          label: labels5,
          data: values5,
          borderColor: getCropColors(cropName, user?.network_partner),
          backgroundColor: 'rgba(174, 155, 126, .5)',
          borderWidth: 2,
          borderDash: [5, 5],
          fill: '+1',
          pointRadius: 0, // Disable points
        },
        {
          label: labels9,
          data: values9,
          borderColor: getCropColors(cropName, user?.network_partner),
          backgroundColor: 'rgba(174, 155, 126, .5)',
          borderWidth: 2,
          borderDash: [5, 5],
          fill: '+1',
          pointRadius: 0, // Disable points
        },
      ],
    };
  }, [lineChartData, relationship, crop, typeParam, dateSet]);

  const formatTicks = (value: number) => {
    if (
      typeParam === 'revenue' ||
      typeParam === 'net_income' ||
      typeParam === 'hiResolutionSatellite'
    ) {
      return '$' + (value / 1000000).toFixed(2) + 'M';
    } else if (typeParam === 'yield' || typeParam === 'yield_performance') {
      return value.toFixed(2); //+ ' bu/ac';
    } else if (typeParam === 'yield_probability') {
      return (value).toFixed(2);
    } else if (typeParam === 'sigma') {
      return value.toFixed(2); //+ ' σ';
    } else if (typeParam === 'ndvi_s2' || typeParam === 'ndvi_pct') {
      return value.toFixed(2);
    }
    return value.toFixed(2);
  };

  const verticalLinePlugin: Plugin<'line'> = useMemo(() => {
    return {
      id: 'verticalLinePlugin',
      afterDraw: (chart) => {
        if (chart.tooltip?.getActiveElements()?.length) return;

        const ctx = chart.ctx;
        const xAxis = chart.scales['x'];
        const selectedDate = moment(date).valueOf();

        const xPosition = xAxis.getPixelForValue(selectedDate);

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(xPosition, chart.scales['y'].top);
        ctx.lineTo(xPosition, chart.scales['y'].bottom);
        ctx.lineWidth = 2;
        ctx.strokeStyle = 'red';
        ctx.stroke();
        ctx.restore();
      },
    };
  }, [date]);

  const mapLineChartOptions: any = useMemo(() => {
    return {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
          text: `${capitalizeFirst(relationship)} Line Chart`,
          font: { size: 20 },
        },
        tooltip: {
          callbacks: {
            label: function (context: any) {
              return `$${context.parsed.y.toFixed(2)}`;
            },
            title: function (tooltipItems: any) {
              return `${new Date(tooltipItems[0].parsed.x).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}`;
            },
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          type: 'time',
          time: {
            tooltipFormat: 'MMMM d, yyyy',
            unit: 'day',
            displayFormats: {
              month: 'MMM yyyy', // Display months in short format (e.g., 'Aug 2024')
            },
          },
          title: {
            display: true,
            text: 'Date',
          },
          ticks: {
            autoSkip: true, // Automatically skip ticks to make space
            maxTicksLimit: 5, // Limit the maximum number of ticks displayed
          },
        },
        y: {
          grid: {
            display: false,
          },
          title: {
            display: true,
            text: `${mapParamsToTitles[(typeParam as keyof typeof mapParamsToTitles) || 'revenue']} (${tabToUnit[typeParam as keyof typeof tabToUnit]})`,
          },
          ticks: {
            callback: function (value: number) {
              // Format y-axis values as millions
              return formatTicks(value);
            },
          },
        },
      },
    };
  }, [relationship]);

  function getChartTitle() {
    if (selectedChart === 'crop') {
      return `${capitalizeFirst(titleCropMap[(crop?.toLowerCase() as keyof typeof cropMap) ?? 'corn'])}`;
    } else if (selectedChart === 'field') {
      return selectedField;
    } else {
      return selectedZone;
    }
  }

  return (
    <div className="flex flex-col">
      <CardHeader
        className="mb-2"
        title={typeParam === 'yield_probability' ? `Probability of Hitting Set Yield Goal by ${getChartTitle()}` : `Expected ${mapParamsToTitles[(typeParam as keyof typeof mapParamsToTitles) || 'revenue']} - ${getChartTitle()} ${year}`}
      />
      
      <Line
        key={date}
        data={chartData!}
        options={mapLineChartOptions}
        plugins={[verticalLinePlugin]}
      />
    </div>
  );
}

export default RelationshipLineChart;
