import { Transition } from '@headlessui/react';
import { signOut } from 'aws-amplify/auth';
import clsx from 'clsx';
import { useMemo, useRef, useState } from 'react';
import { theme } from 'twin.macro';
import CaretDownIcon from '../../../../../assets/icons/CaretDownIcon';
import LogoutIcon from '../../../../../assets/icons/LogoutIcon';
import PaperPlaneIcon from '../../../../../assets/icons/PaperPlaneIcon';
import PhoneIcon from '../../../../../assets/icons/PhoneIcon';
import SettingIcon from '../../../../../assets/icons/SettingIcon';
import SupportIcon from '../../../../../assets/icons/SupportIcon';
import {
  useControlledUser,
  useMainUser,
  useSetControlledUser,
  useSetIsAuthenticated,
} from '../../../../../contexts/auth-context';
import { useOutsideClick } from '../../../../../hooks/use-click-outside';
import { useYears } from '../../../../../hooks/use-years';
import YearSelect from './YearSelect';

const MenuItem = ({ item }: { item: any }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <button
      className="group flex w-full items-center gap-2 rounded-lg data-[focus]:text-primary mt-4 first:mt-0 pl-2"
      onClick={item?.onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <item.icon
        fill={hovered ? theme`colors.primary` : theme`colors.base.500`}
      />
      <p className="group-hover:text-primary">{item.title}</p>
    </button>
  );
};

function UserDropdown() {
  const { years } = useYears();
  const setIsAuthenticated = useSetIsAuthenticated();
  const setControlledUser = useSetControlledUser();
  const mainUser = useMainUser();
  const controlledUser = useControlledUser();
  const user = controlledUser || mainUser;
  const [open, setOpen] = useState(false);

  const dropdownRef = useRef(null);
  const yearSelectRef = useRef(null);
  useOutsideClick([dropdownRef, yearSelectRef], () => setOpen(false));

  const userItems = useMemo(
    () => [
      {
        title: 'Support',
        icon: SupportIcon,
      },
      {
        title: 'Settings',
        icon: SettingIcon,
      },
      {
        title: 'Logout',
        icon: LogoutIcon,
        onClick: async () => {
          setIsAuthenticated?.(false);
          setControlledUser?.(undefined);
          await signOut();
        },
        hidden: !!controlledUser,
      },
    ],
    [controlledUser, setIsAuthenticated, setControlledUser]
  );

  return (
    <div className="w-[305px] relative" ref={dropdownRef}>
      <button
        className={clsx(
          'flex justify-between items-center w-full px-5 pr-3 py-4 userDropdown-btn',
          'rounded-xl border border-base-1000 bg-block-fill text-xl text-base-bold',
          'group-data-[theme=Heartland]/root:bg-light'
        )}
        onClick={() => setOpen(!open)}
      >
        <p>Hello {`${user?.first_name}!`}</p>
        <CaretDownIcon fill={`#7D7F82`} />
      </button>
      <Transition
        show={open}
        enter="transition ease-out duration-75"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div
          className={clsx(
            'absolute',
            'w-[305px] origin-top-right rounded-xl border',
            'border-base-1000 p-4 mt-5 bg-block-fill text-base-500 shadow-xl z-20 userDropdown',
            'group-data-[theme=Heartland]/root:bg-light'
          )}
        >
          {/* {navItems.map((item) => (
              <MenuItem key={item.title}>
                {({ focus }) => (
                  <NavLink to={getHref(item.to, controlledUser)}>
                    {({ isActive }) => (
                      <button
                        className={clsx(
                          "group flex w-full items-center gap-2 rounded-lg group hover:text-primary mb-4",
                          isActive && "text-base-000"
                        )}
                      >
                        <div
                          className={clsx(
                            "w-[3px] h-[full] bg-base-000 rounded-xl self-stretch",
                            "opacity-0",
                            "group-hover:bg-primary",
                            focus && "opacity-100"
                          )}
                        ></div>
                        <item.icon
                          fill={
                            focus
                              ? theme`colors.primary`
                              : isActive
                              ? theme`colors.base.000`
                              : theme`colors.base.500`
                          }
                        />
                        {item.title}
                      </button>
                    )}
                  </NavLink>
                )}
              </MenuItem>
            ))} */}

          <YearSelect years={years} ref={yearSelectRef} />

          <div className="my-1 h-px bg-base-900" />

          <div
            className={clsx(
              'group flex w-full items-center gap-2 rounded-lg text-base-500 text-md-bold mt-4 pl-2'
            )}
          >
            My Contact Info
          </div>

          <a
            href={`mailto:${user?.email}`}
            className="group flex w-full items-center gap-2 rounded-lg underline text-blue mt-2 pl-2"
          >
            <PaperPlaneIcon fill={theme`colors.blue`} />
            {user?.email}
          </a>

          <p className="group flex w-full items-center gap-2 rounded-lg underline text-blue mt-2 pl-2">
            <PhoneIcon fill={theme`colors.blue`} />
            {user?.phone}
          </p>

          <div className="my-1 h-px bg-base-900 mt-4" />

          {userItems
            ?.filter((item) => !item.hidden)
            .map((item) => <MenuItem key={item.title} item={item} />)}
        </div>
      </Transition>
    </div>
  );
}

export default UserDropdown;
