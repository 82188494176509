import {
  ChevronDownIcon,
  ChevronUpIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import {
  InvalidateQueryFilters,
  useQueryClient
} from '@tanstack/react-query';
import { useMemo } from 'react';
import Marquee from 'react-simple-marquee';
import CornIcon from '../../../assets/icons/CornIcon';
import SoybeansIconColored from '../../../assets/icons/SoybeansIconColored';
import WheatIcon from '../../../assets/icons/WheatIcon';
import {
  useUser
} from '../../../contexts/auth-context';
import {
  useDeleteWatchList,
  useWatchList,
} from '../../../hooks/market/use-watch-list';
import { formatAlertDate, formatStrikePriceToDollars } from '../../../utils';

const convertToWatchListItem = (data: any) => {
  let icon;
  switch (data.crop) {
    case 'Corn':
      icon = <CornIcon />;
      break;
    case 'HRW Wheat':
      icon = <WheatIcon />;
      break;
    case 'SRW Wheat':
      icon = <WheatIcon />;
      break;
    case 'Soybeans':
      icon = <SoybeansIconColored />;
      break;
    default:
      icon = null;
  }

  const change = data.change.startsWith('-') ? 'down' : 'up';
  return {
    id: data['user_id-type-crop-basis_location'],
    commodity: data.crop,
    monthYear: formatAlertDate(data.date),
    price:
      data.type === 'basis'
        ? `$${parseFloat(data.lastBasis).toFixed(2)}`
        : `$${parseFloat(data.lastPrice).toFixed(2)}`,
    change:
      data.type === 'basis'
        ? `$${parseFloat(data.basisChange).toFixed(2)}`
        : `$${parseFloat(data.change).toFixed(2)}`,
    changeType: change,
    icon: icon,
    type: data.type.charAt(0).toUpperCase() + data.type.slice(1),
    strikePrice: formatStrikePriceToDollars(data.strike_price) ?? '',
    optionType: data.option_type ?? '',
    basisLocation: data.basisLocation ?? '',
    basisCompany: data.basisCompany ?? '',
    date: data.date,
  };
};

const WatchList = () => {
  const user = useUser();
  const watchList = useWatchList(user?.id || '');
  const { mutate: deleteWatchListItem } = useDeleteWatchList();
  const queryClient = useQueryClient();

  const memoizedWatchList = useMemo(() => {
    return (
      watchList &&
      watchList.data &&
      watchList.data.length > 0 &&
      watchList.data.map((item: any) => convertToWatchListItem(item))
    );
  }, [watchList]);

  if (!watchList) {
    return null;
  }

  return (
    <div className="space-y-4">
      <div className="flex marquee-continuous space-x-8 flex-start overflow-x-auto hide-scrollbar">
        <Marquee
          speed={2}
          style={{
            height: 60,
            width: '100%',
          }}
        >
          {memoizedWatchList &&
            memoizedWatchList.length > 0 &&
            memoizedWatchList.map((item: any, index: number) => (
              <div
                key={index}
                className="flex mr-8 space-x-4 flex-row items-center justify-between rounded-md relative group"
              >
                <div className="flex items-center space-x-0">{item.icon}</div>
                <div className="flex flex-col">
                  <p className=" whitespace-nowrap text-darkGray">
                    {item.commodity} {item.type}
                  </p>
                  <p className="text-darkGray whitespace-nowrap">
                    {item.monthYear}
                  </p>
                </div>
                {item.type === 'Options' && (
                  <div
                    className={`flex flex-col items-center space-x-0 ${item.changeType === 'up' ? 'text-green' : 'text-red'}`}
                  >
                    <p className="">{item.strikePrice}</p>
                    <div className="flex">
                      {item.changeType === 'up' ? (
                        <ChevronUpIcon />
                      ) : (
                        <ChevronDownIcon />
                      )}
                      <p className="text-sm">{item.optionType}</p>
                    </div>
                  </div>
                )}
                <div
                  className={`flex flex-col items-center space-x-0 ${item.changeType === 'up' ? 'text-green' : 'text-red'}`}
                >
                  <p className="">{item.price}</p>
                  <div className="flex">
                    {item.changeType === 'up' ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    )}
                    <p className="text-sm">{item.change}</p>
                  </div>
                </div>
                <div
                  className="absolute mb-auto top-0 right-[-25px] cursor-pointer text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
                  onClick={() => {
                    const confirmation = confirm(
                      `Are you sure you want to delete item: ${item.commodity} ${item.monthYear}`
                    );
                    if (!confirmation) {
                      return;
                    }
                    deleteWatchListItem(item, {
                      onSuccess: () => {
                        alert('Item deleted successfully');
                        queryClient.invalidateQueries([
                          'market/watch-list',
                          user?.id ?? '',
                        ] as InvalidateQueryFilters);
                      },
                      onError: (error: any) => {
                        console.error('Error deleting item:', error);
                        alert('Failed to delete item. Please try again.');
                      },
                    });
                  }}
                >
                  <XCircleIcon color="gray" className="h-6 w-6" />
                </div>
              </div>
            ))}
        </Marquee>
      </div>
    </div>
  );
};

export default WatchList;
