import { useQueries, useQuery } from '@tanstack/react-query';
import { getHealthChecks } from '../../api/health-api';



export const useHealthData = (types: string[]) => {
    const { data, ...rest } = useQuery({
      queryKey: ['health', types],
      queryFn: () => getHealthChecks(types),
      enabled: !!types,
    });
  
    return { data: data?.data };
  };