import {
  BookmarkIcon,
  PlusCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/commons/Button';
import Disclosure from '../../../components/commons/Disclosure';
import TextInput from '../../../components/commons/inputs/TextInput';
import Select from '../../../components/commons/Select';
import Table, { TableConfigs } from '../../../components/commons/table/Table';
import TableLayout from '../../../components/dashboard/commons/TableLayout';
import { mapTabOptions } from '../../../constants/selectCommonConfigs';
import { createMapStudyTableConfig } from '../../../constants/tableConfigs';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useControlledUser, useUser } from '../../../contexts/auth-context';
import { useMapContext } from '../../../contexts/map-context';
import { capitalizeFirst } from '../../../utils';
import { useCreateNote, useDeleteStudy, useStudyData, useStudyNote } from '../../../hooks/map/use-map';

export default function StudyList() {
  const navigate = useNavigate();
  const { type: typeParam = '' } = useParams();
  const queryClient = useQueryClient();
  const {
    setStudies,
    selectedStudyIndex,
    studyDetails,
    setStudyDetails,
    setSelectedStudyIndex,
    creatingStudy,
    setCreatingStudy,
    setCreateStudy,
    myFeatureCollection,
    setMyFeatureCollection,
    setEditMode,
    setSaveEditingStudy,
    editMode,
    setSelectedDrawMode,
    setModalMode,
  } = useMapContext();
  const [showAllStudies, setShowAllStudies] = useState(true);
  const [studyNotePk, setStudyNotePk] = useState<string | null>(null);
  const [studyNodeXIndex, setStudyNodeXIndex] = useState<string | null>(null);
  const [trigger, setTrigger] = useState(0);

  const studyNoteData = useStudyNote(studyNotePk ?? '', studyNodeXIndex ?? '');
  const { mutate: deleteStudy } = useDeleteStudy();
  const { mutate: createNote } = useCreateNote();
  const user = useControlledUser();
  const year = useYearFilter();
  const studies = useStudyData(user?.id ?? '', year!);


  // temporary
  const type = typeParam === 'hiResolutionSatellite' ? 'revenue' : typeParam;

  const studyTableConfig = (): TableConfigs => {
    return {
      cols: [
        {
          key: 'name',
          name: 'Name',
        },
        {
          key: 'year',
          name: 'Year',
        },
        {
          key: 'crop',
          name: 'Crop',
        },
        {
          key: 'type',
          name: 'Type',
        },
        {
          key: 'area',
          name: 'Acres',
        },
        {
          key: 'lon',
          name: 'lon',
        },
        {
          key: 'lat',
          name: 'lat',
        },
        {
          key: 'x_value',
          name: 'Value',
        },
        {
          key: 'x_bin',
          name: `Value Range`,
        },
        {
          key: 'View Study',
          name: 'View Study',
          type: 'element',
          element: (item) => (
            <Button
              onClick={() => {
                if (
                  selectedStudyIndex ===
                  item.user_id_type_crop_year + item.x_index
                ) {
                  setSelectedStudyIndex(-1);
                  return;
                }
                setSelectedStudyIndex(
                  item.user_id_type_crop_year + item.x_index
                );
              }}
              color={
                selectedStudyIndex ===
                item.user_id_type_crop_year + item.x_index
                  ? 'default'
                  : 'primary'
              }
              size="sm"
            >
              {selectedStudyIndex ===
              item.user_id_type_crop_year + item.x_index ? (
                <p>Hide</p>
              ) : (
                <p>View</p>
              )}
            </Button>
          ),
        },
      ],
    };
  };

  const studyNote = useMemo(() => {
    return studyNoteData.data?.note ?? '';
  }, [studyNoteData]);

  const rowActions = {
    edit: {
      onSelect: (item: any) => {
        if (editMode === 'edit') {
          setEditMode('');
          setMyFeatureCollection({
            type: 'FeatureCollection',
            features: [],
          });
          return;
        }
        setEditMode('edit');

        // remove all polygons and redraw study polyogns on map
        const polygons = item.polygons;
        const features = polygons.map((polygon: any) => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: polygon,
            },
            properties: {},
          };
        });
        setMyFeatureCollection({
          type: 'FeatureCollection',
          features: features,
        });
        setSaveEditingStudy(item);
      },
    },
    delete: (item: any) => {
      deleteStudy(item, {
        onSuccess: () => {
          alert('Study Deleted');
          // invalidate the query
          queryClient.invalidateQueries({
            queryKey: ['map/study-data'],
          });
        },
        onError: (error: unknown, _variables: any, _context: unknown) => {
          alert(`Failed to delete study. Please try again. ${error}`);
        },
      });
    },
    note: {
      onSelect: (item: any) => {
        //
        setTrigger(trigger + 1);
        setStudyNotePk(item.user_id_type_crop_year);
        setStudyNodeXIndex(item.x_index);
      },
      onSave: (item: any, value: string) => {
        createNote(
          {
            user_id_type_crop_year: item.user_id_type_crop_year,
            xIndex: item.x_index,
            note: value,
          },
          {
            onSuccess: () => {
              alert('Note Saved');
              // invalidate the query
              queryClient.invalidateQueries({
                queryKey: ['map/createNote'],
              });
            },
            onError: (error: unknown, _variables: any, _context: unknown) => {
              alert(`Failed to saven note. Please try again. ${error}`);
            },
          }
        );
      },
      title: 'Add Note',
    },
  };

  useEffect(() => {
    if (!studies.data) {
      return;
    }
    setStudies(studies.data);
  }, [studies?.data]);

  const formattedTableData = useMemo(() => {
    return studies.data
      ?.map((data: any) => {
        return {
          ...data,
          x_value: parseFloat(data.x_value).toFixed(2),
          area: parseFloat(data.area).toFixed(2),
          lon: parseFloat(data.lon).toFixed(2),
          lat: parseFloat(data.lat).toFixed(2),
        };
      })
      .filter((data: any) => {
        if (showAllStudies) {
          return true;
        }
        return data.type === type;
      });
  }, [studies.data, type, showAllStudies]);

  return (
    <Disclosure title="Custom Studies">
      {creatingStudy ? (
        <div className="cursor-pointer justify-center items-center flex flex-col p-4 px-6 w-full">
          <p className="text-xl ">Save {capitalizeFirst(type)} Study</p>
          <TextInput
            name="studyName"
            placeholder="Study Name"
            className="mt-4"
            onChange={(e) =>
              setStudyDetails({ ...studyDetails, name: e.target.value })
            }
          />
          <div className="rounded-xl w-full mt-4 justify-center items-center flex overflow-x-hidden whitespace-nowrap">
            <TableLayout title={'Custom Studies'}>
              <Table
                configs={createMapStudyTableConfig(type)}
                data={[studyDetails]}
              />
            </TableLayout>
          </div>

          <div className="flex gap-4 mt-4">
            <div
              onClick={() => setCreateStudy(true)}
              className="cursor-pointer bg-primary flex gap-2 rounded-xl px-4 py-2"
            >
              <BookmarkIcon color={''} className="w-6 h-6" />
              <p>Save</p>
            </div>
            <div
              onClick={() => setCreatingStudy(false)}
              className="flex gap-2 card px-4 py-2"
            >
              <XCircleIcon color={'darkGray'} className="w-6 h-6" />
              <p>Cancel</p>
            </div>
          </div>
        </div>
      ) : (
        studies &&
        studies.data && (
          <div className="">
            <div className="flex items-center gap-4 p-2 group-data-[theme=Heartland]/root:bg-tan">
              <Select
                options={mapTabOptions}
                value={showAllStudies ? 'all' : typeParam}
                name="tab"
                defaultValue="all"
                className="max-w-[160px]"
                onChange={(e) => {
                  if (e.target.value === 'all') {
                    setShowAllStudies(true);
                    return;
                  }
                  setShowAllStudies(false);
                  const url = window.location.pathname;
                  // set url to the selected tab
                  const newUrl = `${url
                    .split('/')
                    .slice(0, url.split('/').length - 1)
                    .join('/')}/${e.target.value}`;
                  // Update the URL without reloading the page
                  navigate(newUrl);
                }}
              />

              {creatingStudy ? (
                <div
                  onClick={() => {
                    setCreatingStudy(false);
                  }}
                  className="flex cursor-pointer gap-2 items-center"
                >
                  <XCircleIcon
                    className="w-6 h-6 text-darkGray"
                    fontSize={16}
                  />
                  <p>Remove Study Filter</p>
                </div>
              ) : (
                <Button
                  onClick={() => {
                    if (myFeatureCollection.features.length === 0) {
                      alert('Please draw a polygon to create a study');
                      return;
                    }
                    setCreatingStudy(true);
                    setSelectedDrawMode(0)
                    setModalMode('')
                  }}
                  startIcon={<PlusCircleIcon className="size-7 " />}
                  color="primary"
                  className="!w-fit"
                >
                  <p>Create Study From Selection</p>
                </Button>
              )}
            </div>
            <Table
              configs={studyTableConfig()}
              data={formattedTableData ?? []}
              pagination={{ size: 5 }}
              rowActions={rowActions}
              initNote={studyNote}
              noteTrigger={trigger}
            />
          </div>
        )
      )}
    </Disclosure>
  );
}
