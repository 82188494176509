import moment from 'moment';
import { capitalizeFirst } from '../utils';

// type set to any because of x axis type is not recognized
export const basisChartOptions: any = (symbol: string) => {
  return {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Disable the legend
      },
      title: {
        display: true,
        text: `Basis Line Chart for ${symbol}`,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(context.parsed.y);
            }
            return label;
          },
          title: function (tooltipItems: any) {
            const date = tooltipItems[0].parsed.x;
            return `${moment(date).format('MMMM d, yyyy')}`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'MMM d, yyyy',
        },
        title: {
          display: false,
          text: 'Date',
        },
        ticks: {
          source: 'auto',
          font: {
            size: 13,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 13,
          },
        },
        title: {
          display: false,
          text: 'Basis',
        },
      },
    },
  };
};

export const futuresChartOptions = (symbol: string): any => {
  return {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: `Price Chart for ${symbol}`,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'MMM d, yyyy',
        },
        title: {
          display: false,
          text: 'Date',
        },
        ticks: {
          source: 'auto',
          font: {
            size: 13,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        beginAtZero: false,
        title: {
          display: false,
          text: 'Price',
        },
        ticks: {
          callback: function (value: any) {
            return value.toFixed(2);
          },
          font: {
            size: 13,
          },
        },
      },
    },
    interaction: {
      mode: 'nearest',
      intersect: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };
};

export const optionsChartOptions = (symbol: string): any => {
  return {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: `Options ${symbol} HLCO Chart`,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'MMM d, yyyy',
        },
        title: {
          display: false,
          text: 'Date',
        },
        ticks: {
          source: 'auto',
          font: {
            size: 13,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        beginAtZero: false,
        title: {
          display: false,
          text: 'Price',
        },
        ticks: {
          callback: function (value: any) {
            return value.toFixed(2);
          },
          font: {
            size: 13,
          },
        },
      },
    },
    interaction: {
      mode: 'nearest',
      intersect: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };
};

export const spreadsChartOptions = (symbol: string): any => {
  return {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: `Spread ${symbol} HLCO Chart`,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'MMM d, yyyy',
        },
        title: {
          display: false,
          text: 'Date',
        },
        ticks: {
          source: 'auto',
          font: {
            size: 13,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        beginAtZero: false,
        title: {
          display: true,
          text: 'Price',
        },
        ticks: {
          callback: function (value: any) {
            return value.toFixed(2);
          },
          font: {
            size: 13,
          },
        },
      },
    },
    interaction: {
      mode: 'nearest',
      intersect: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };
};
