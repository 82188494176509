import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createNoteApi,
  createStudyApi,
  deleteStudyApi,
  getLineChartApi,
  getStudyNoteApi,
  getTableData,
  getUserStudies,
  updateStudyApi,
} from '../../api/map-api';

export const useTableData = (
  userId: string,
  crop: string,
  type: string,
  level: string,
  year: string,
  date: string,
  path?: string
) => {
  const res = useQuery({
    queryKey: ['map/table-data', userId, crop, type, year, level, date],
    queryFn: () => getTableData(userId, crop, type, level, year, date, path),
    enabled: !!userId,
  });

  return { data: res.data };
};

export const useStudyData = (userId: string, year: string) => {
  const res = useQuery({
    queryKey: ['map/study-data', userId, year],
    queryFn: () => getUserStudies(userId, year),
    enabled: !!userId,
  });

  return { data: res.data };
};

export const useLineChartData = (
  userId: string,
  year: string,
  crop: string,
  type: string,
  selectedFieldIndex: string,
  tab: string,
  zone: string
) => {
  const res = useQuery({
    queryKey: [
      'map/line-chart-data',
      userId,
      year,
      crop,
      type,
      selectedFieldIndex,
      tab,
      zone
    ],
    queryFn: () =>
      getLineChartApi(userId, year, crop, type, selectedFieldIndex, tab, zone),
    enabled: !!userId,
  });

  return { data: res.data };
};

export const useStudyNote = (pk: string, xIndex: string) => {
  const res = useQuery({
    queryKey: ['map/study-note-data', pk, xIndex],
    queryFn: () => getStudyNoteApi(pk, xIndex),
    enabled: !!pk,
  });

  return { data: res.data };
};

export const useCreateNote = () => {
  const mutation = useMutation({
    mutationKey: ['map/createNote'],
    mutationFn: (payload: any) => createNoteApi(payload),
  });

  return mutation;
};

export const useCreateStudy = () => {
  const mutation = useMutation({
    mutationKey: ['map/create-study'],
    mutationFn: (payload: any) => createStudyApi(payload),
  });

  return mutation;
};


export const useUpdateStudy = () => {
  const mutation = useMutation({
    mutationKey: ['map/updateStudy'],
    mutationFn: (payload: any) => updateStudyApi(payload),
  });

  return mutation;
};


export const useDeleteStudy = () => {
  const mutation = useMutation({
    mutationKey: ['map/deleteStudy'],
    mutationFn: (payload: any) => deleteStudyApi(payload),
  });

  return mutation;
};
