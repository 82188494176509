import { useMemo } from 'react';
import Table, { TableConfigs } from '../../../commons/table/Table';
import TableLayout from '../../commons/TableLayout';
import Checkbox from '../../../commons/Checkbox';

interface ChemicalListProps {
  data: any;
  zone: any;
  onSelect: (id: number) => void;
}

export default function ChemicalList({
  data,
  zone,
  onSelect,
}: ChemicalListProps) {
  const tableConfigs: TableConfigs = useMemo(() => {
    return {
      cols: [
        {
          name: 'Select Zone',
          key: 'id',
          type: 'element',
          element: (item) => (
            <Checkbox
              name="id"
              label={item.name}
              checked={zone.id === item.id}
              onChange={(checked: boolean) => checked && onSelect(item.id)}
              rounded
            />
          ),
        },
        { name: 'Product', key: 'product' },
        { name: 'Brand', key: 'brand' },

        { name: 'Unit', key: 'unit' },
        {
          name: 'Price Per Unit',
          key: 'costPerUnit',
          type: 'currency',
          total: true,
        },
        {
          name: 'Rate Per Acre',
          key: 'ratePerAcre',
          type: 'number',
          total: true,
        },
        { name: 'Total Units', key: 'totalUnits', type: 'number', total: true },
        {
          name: 'Total Cost',
          key: 'totalCost',
          type: 'currency',
          total: true,
        },
        { name: 'Application Period', key: 'applicationPeriod' },
      ],
    };
  }, [onSelect, zone.id]);
  return (
    <TableLayout title={'Chemical Application'}>
      <Table
        data={data}
        configs={tableConfigs}
        hasTotalRow
        totalRowVariant="secondary"
      />
    </TableLayout>
  );
}
