import { ArrowDownIcon } from '@heroicons/react/24/solid';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-chart-financial';
import {
  CandlestickController,
  CandlestickElement,
} from 'chartjs-chart-financial';
import { useEffect, useMemo, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { Form, FormProvider, useForm } from 'react-hook-form';

import ReactSlider from 'react-slider';

import regression from 'regression';
import CornIcon from '../../../assets/icons/CornIcon';
import SoybeansIconColored from '../../../assets/icons/SoybeansIconColored';
import WheatIcon from '../../../assets/icons/WheatIcon';
import {
  CodeToCrop,
  CropToCode,
  MonthToCode,
  cropToMonthOptions,
  yearOptions,
} from '../../../constants';
import { futuresChartOptions } from '../../../constants/chartConfigs';
import { useMarketContext } from '../../../contexts/market-context';
import { useWebsocketContext } from '../../../contexts/websocket-context';
import { useAllFutures, usePrices } from '../../../hooks/market/use-futures';
import { useWebSocketDataV2 } from '../../../hooks/websocket/use-websocket-data';
import Select from '../../commons/Select';
import { last } from 'lodash';
import CropIcon from '../../commons/CropIcon';

ChartJS.register(
  CategoryScale,
  LinearScale,
  CandlestickController,
  CandlestickElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement
);

export const commodityOptions = [
  { value: 'Corn', label: 'Corn', icon: <CropIcon cropName='Corn' /> },
  { value: 'HRW Wheat', label: 'HRW Wheat', icon:<CropIcon cropName='Wheat' /> },
  { value: 'SRW Wheat', label: 'SRW Wheat', icon: <CropIcon cropName='Wheat' /> },
  { value: 'Soybeans', label: 'Soybeans', icon: <CropIcon cropName='Soybeans' /> },
];

const polynomialRegression = (data: any, degree = 2) => {
  const startTime = data[0].x;
  const x = data.map((d: any) => d.x - startTime); // normalized x values
  const y = data.map((d: any) => d.c);
  const formattedPoints = x.map((xi: any, i: number) => [xi, y[i]]);
  const result = regression.polynomial(formattedPoints, { order: 9 });
  return result.points.map((point, i) => ({
    x: point[0] + startTime, // use the original time values
    y: point[1],
  }));
};

function PriceChart() {
  const methods = useForm();

  const [sliderValues, setSliderValues] = useState<[number, number]>([0, 0]);
  const { pricesSymbolRef, trigger, setTrigger } = useMarketContext();
  const { setRoots } = useWebsocketContext();
  const prices = usePrices(pricesSymbolRef.current);
  const futures = useAllFutures(pricesSymbolRef.current.slice(0, 2));

  const crop = methods.watch('crop', 'Corn');
  const month = methods.watch('month', 'december');
  const year = methods.watch('year', '2024');

  const msgHLCO: any = useWebSocketDataV2('HLCO');
  const msgBidAsk: any = useWebSocketDataV2('Bid_Ask');

  const priceChartData = useMemo(() => {
    if (!prices.data) return [];
    return prices.data.price?.map((item: any) => {
      if (
        msgHLCO &&
        msgHLCO.data.symbol === item.symbol &&
        msgHLCO.data.trade_date === item.trade_date
      ) {
        return {
          x: new Date(item.trade_date).getTime(),
          o: msgHLCO.data?.open
            ? parseFloat(msgHLCO.data.open)
            : parseFloat(item.open),
          h: msgHLCO.data?.high
            ? parseFloat(msgHLCO.data.high)
            : parseFloat(item.high),
          l: msgHLCO.data?.low
            ? parseFloat(msgHLCO.data.low)
            : parseFloat(item.low),
          c: msgHLCO.data?.close
            ? parseFloat(msgHLCO.data.close)
            : parseFloat(item.close),
        };
      } else {
        return {
          x: new Date(item.trade_date).getTime(),
          o: parseFloat(item.open),
          h: parseFloat(item.high),
          l: parseFloat(item.low),
          c: parseFloat(item.close),
        };
      }
    });
  }, [prices.data, crop, month, year]);

  const minDate = useMemo(() => {
    return priceChartData.length > 0
      ? Math.min(...priceChartData.map((item: any) => item.x))
      : 0;
  }, [priceChartData]);

  const maxDate = useMemo(() => {
    return priceChartData.length > 0
      ? Math.max(...priceChartData.map((item: any) => item.x))
      : 0;
  }, [priceChartData]);

  useEffect(() => {
    if (minDate && maxDate) {
      setSliderValues([minDate, maxDate]);
    }
  }, [minDate, maxDate]);

  const filteredPriceChartData = useMemo(() => {
    setRoots([CropToCode[crop as keyof typeof CodeToCrop]]);
    pricesSymbolRef.current = CropToCode[crop] + MonthToCode[month] + year[3];
    return priceChartData.filter(
      (item: any) => item.x >= sliderValues[0] && item.x <= sliderValues[1]
    );
  }, [priceChartData, sliderValues, crop, month, year]);

  const trendLineData = useMemo(() => {
    return filteredPriceChartData.length > 0
      ? polynomialRegression(filteredPriceChartData)
      : [];
  }, [filteredPriceChartData]);

  const currentContractDetails = useMemo(() => {
    const futurescontract = futures.data?.find((item: any) => {
      return item.symbol === pricesSymbolRef.current;
    });
    return {
      price: futurescontract?.bid,
      bid: futurescontract?.bid,
      ask: futurescontract?.ask,
      change: futurescontract?.change,
      last: futurescontract?.last,
      symbol: futurescontract?.symbol,
      last_trade_time: futurescontract?.last_trade_time,
    };
  }, [priceChartData, futures.data]);

  const [updatedContract, setUpdatedContract] = useState<any>(null);

  useEffect(() => {
    if (
      msgBidAsk &&
      msgBidAsk.data?.symbol === currentContractDetails.symbol &&
      (!currentContractDetails.last_trade_time ||
        msgBidAsk.data?.last_trade_time >
          currentContractDetails.last_trade_time)
    ) {
      const tmpUpdateContract = {
        ...currentContractDetails,
        bid: msgBidAsk.data?.bid
          ? msgBidAsk.data?.bid
          : currentContractDetails.bid,
        ask: msgBidAsk.data?.ask
          ? msgBidAsk.data?.ask
          : currentContractDetails.ask,
        change: msgBidAsk.data?.change,
        last: msgBidAsk.data?.trade_price,
        last_trade_time: msgBidAsk.data?.last_trade_time,
      };
      setUpdatedContract(tmpUpdateContract);
    } else {
      setUpdatedContract(currentContractDetails);
    }
  }, [msgBidAsk.data, currentContractDetails]);

  const data: any = {
    datasets: [
      {
        backgroundColors: {
          up: '#80bc00',
          down: '#eab208',
          unchanged: 'rgba(143, 143, 143, 1)'
        },
        borderColors: {
          up: '#80bc00',
          down: '#eab208',
          unchanged: 'rgba(143, 143, 143, 1)'
        },
        label: pricesSymbolRef.current,
        data: filteredPriceChartData,
        type: 'candlestick',
        borderColor: '#00c0ef',
        backgroundColor: 'rgba(0, 192, 239, 0.2)',

      },
      // {
      //   label: 'Trend Line',
      //   data: trendLineData,
      //   type: 'line',
      //   borderColor: 'green',
      //   borderWidth: 2,
      //   fill: true,
      //   pointRadius: 0,
      // },
    ],
  };

  return (
    <>
      <FormProvider {...methods}>
        <Form>
          <p className="text-lg pt-0 px-0 text-darkGray">Contract</p>
          <div className="flex items-center justify-between px-0 pb-4 pt-0 text-lightGray rounded-md">
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-2 p-0 rounded-md">
                <Select
                  options={commodityOptions}
                  defaultValue={'Corn'}
                  name="crop"
                  onChange={(e) => {
                    setTrigger(trigger + 1);
                  }}
                  textClassName='text-xl'
                />
              </div>
              <div className="flex items-center space-x-2 p-2 rounded-md">
                <Select
                  name="month"
                  options={
                    cropToMonthOptions[crop as keyof typeof cropToMonthOptions]
                  }
                  onChange={(e) => {
                    setTrigger(trigger + 1);
                  }}
                  defaultValue={'december'}
                  className="text-sm w-full"
                  textClassName='text-xl'
                />
              </div>
              <div className="flex items-center space-x-2 p-0 rounded-md">
                <Select
                  name="year"
                  options={yearOptions}
                  defaultValue={'2024'}
                  className="w-full"
                  textClassName='text-xl'
                />
              </div>
            </div>
            <div className="flex items-center space-x-8">
              <div className="text-red flex items-center space-x-2">
                <span className="text-2xl text-base-bold">
                  ${updatedContract?.last ?? '0'}
                </span>
                <div className="flex items-center space-x-1">
                {updatedContract?.change && parseFloat(updatedContract?.change) > 0 ? (
                    <ArrowDownIcon className="w-4 h-4" />
                  ) : (
                    <ArrowDownIcon className="w-4 h-4" />
                  )}
                  <span className='text-xl'>
                    ${updatedContract?.change ?? '0'} (
                    {(
                      (100 * parseFloat(updatedContract?.change ?? '0')) /
                      parseFloat(updatedContract?.last ?? '0')
                    ).toFixed(2)}
                    %)
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </FormProvider>
      <div className="card flex flex-col">
        <div className='px-2'>
        <Chart
          type="candlestick"
          data={data}
          options={futuresChartOptions(pricesSymbolRef.current)}
        />
        </div>
        
        <div className="w-full mb-4 pl-14 border-0 h-full">
          {priceChartData && priceChartData.length > 0 && (
            <div className="pr-2">
              <ReactSlider
                className="horizontal-slider2"
                thumbClassName="example-thumb2"
                trackClassName="example-track2"
                min={minDate}
                max={maxDate}
                defaultValue={[minDate, maxDate]}
                value={sliderValues}
                ariaValuetext={(state) =>
                  `Thumb value ${new Date(state.valueNow).toLocaleDateString()}`
                }
                renderThumb={(props, state) => {
                  const { key, ...restProps } = props;
                  return (
                    <div key={state.index} {...restProps}>
                      {}
                    </div>
                  );
                }}
                pearling
                minDistance={10}
                onChange={(values) =>
                  setSliderValues(values as [number, number])
                }
              />
              <div className="w-full h-full mt-10 flex items-center justify-center">
                <span>{new Date(sliderValues[0]).toDateString()}</span>&nbsp; -
                &nbsp;<span>{new Date(sliderValues[1]).toDateString()}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PriceChart;
