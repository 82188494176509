import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import CardHeader from '../../../components/commons/CardHeader';
import { mapParamsToTitles, tabToUnit } from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useControlledUser, useUser } from '../../../contexts/auth-context';
import { useMapContext } from '../../../contexts/map-context';
import { useTableData } from '../../../hooks/map/use-map';
import tinycolor from 'tinycolor2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  ArrowsPointingOutIcon,
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/solid';
import { set } from 'lodash';
import { format } from 'path';
import useTypeParams from './useTypeParams';


function RevenueByField({ crop }: { crop: string }) {
  const user = useControlledUser();
  const year = useYearFilter();
  const {
    selectedDate: date,
    setSelectedField: setSelectedMapField,
    selectedField: selectedMapField,
    setSelectedFieldIndex,
    selectedField,
    setSelectedField,
  } = useMapContext();

  const typeParam = useTypeParams();
  const type = typeParam === 'hiResolutionSatellite' ? 'revenue' : typeParam;
  const tableData = useTableData(
    user?.id || '',
    crop,
    type!,
    'field',
    year!,
    date,
    'field'
  );

  const itemsPerPage = 7;
  const [searchTerm, setSearchTerm] = useState<string>('');

  type DataType = {
    field: string;
    crop: string;
    x_bin: string;
    area: string;
    field_index: string;
  };

  const cropColors: { [key: string]: string } = {
    Soybeans: 'rgba(174, 154, 125, 1)',
    Corn: 'rgba(255, 183, 27, 1)',
    Other: 'rgba(244, 39, 39, 1)',
    Wheat: 'rgba(98,162,235,1)',
  };

  const labels: { [key: string]: string[] } = useMemo(
    () =>
      tableData?.data?.reduce((acc: { [key: string]: string[] }, dataItem: DataType) => {
        const xBin = dataItem.x_bin === 'nan' ? 'Other' : dataItem.x_bin;
        if (!acc[dataItem.field]) {
          acc[dataItem.field] = [];
        }
        if (!acc[dataItem.field].includes(xBin)) {
          acc[dataItem.field].push(xBin);
        }
        // Sorting the array for each field
        acc[dataItem.field] = acc[dataItem.field].sort((a, b) => {
          if (a === 'Other') return -1;
          let numA = parseFloat(a.split(' - ')[0].replace(/[^0-9.-]+/g, ''));
          let numB = parseFloat(b.split(' - ')[0].replace(/[^0-9.-]+/g, ''));
          return numA - numB;
        });
        return acc;
      }, {}),
    [tableData?.data]
  );
  


  const [fieldIndexMap, setFieldIndexMap] = useState<{ [key: string]: string }>(
    {}
  );

  const fields = useMemo(() => {
    if (!tableData?.data) return {};

    const fieldData: { [key: string]: { data: number[]; crop: string } } = {};
    const tmpFieldIndexMap: { [key: string]: string } = {};
    tableData.data.forEach((dataItem: DataType) => {
      tmpFieldIndexMap[dataItem.field] = dataItem.field_index;
      let area = parseFloat(dataItem.area);
      if (isNaN(area)) area = 0;

      if (!fieldData[dataItem.field]) {
        fieldData[dataItem.field] = {
          data: Array(labels[selectedField]?.length ?? 0).fill(0),
          crop: dataItem.crop,
        };
      }

      const index = labels[selectedField]?.indexOf(dataItem.x_bin);
      if (index !== -1) {
        fieldData[dataItem.field].data[index] = area;
      }
    });

    setFieldIndexMap(tmpFieldIndexMap);

    return fieldData;
  }, [tableData?.data, labels]);

  // Get unique field names from the data
  const uniqueFields = useMemo(
    () =>
      Object.keys(fields).sort((a, b) => {
        const cropA = fields[a].crop || 'Unknown Crop';
        const cropB = fields[b].crop || 'Unknown Crop';
        const comp = cropA.localeCompare(cropB);
        // if the crops are the same, sort by field name
        if (comp === 0) return a.localeCompare(b);
        return comp;
      }),
    [fields]
  );

  // Set the first field as the default selected field
  useEffect(() => {
    if (uniqueFields.length > 0 && !selectedField) {
      setSelectedField(uniqueFields[0]);
      setSelectedFieldIndex(fieldIndexMap[uniqueFields[0]]);
    }
  }, [uniqueFields, selectedField]);

  const filteredFields = useMemo(() => {
    return uniqueFields.filter((fieldName) =>
      fieldName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [uniqueFields, searchTerm]);

  const gradientCropColors: { [key: string]: string } = {
    Soybeans: 'rgba(224, 199, 162, 1)',
    Corn: 'rgba(255, 203, 89, 1)',
    Other: 'rgba(255, 102, 102, 1)',
    Wheat: 'rgba(147, 195, 250, 1)',
  };

  const datasets = useMemo(() => {
    if (!fields[selectedField]) return [];

    const fieldCrop = fields[selectedField]?.crop;

    return [
      {
        label: selectedField,
        data: fields[selectedField].data,
        backgroundColor: function (context: any) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          const cropColor = cropColors[fieldCrop];
          const gradientCropColor = gradientCropColors[fieldCrop];

          if (!chartArea) {
            return cropColor;
          }

          const bar = context.chart.getDatasetMeta(context.datasetIndex).data[
            context.index
          ];
          if (!isFinite(bar?.y) || !isFinite(bar.x) || !isFinite(bar.height)) {
            return cropColor;
          }
          const gradient = ctx.createLinearGradient(
            0,
            bar?.y,
            0,
            bar?.y + bar.height - 10
          ); // Vertical gradient for the current bar
          gradient.addColorStop(0, gradientCropColor);
          gradient.addColorStop(0.5, cropColor);
          return gradient;
        },
        borderWidth: 1,
        borderRadius: 5,
        barThickness: 20,
      },
    ];
  }, [fields, selectedField, cropColors]);

  const options: any = {
    layout: {
      padding: {
        left: 0,
        right: 70,
        top: 0,
        bottom: 0,
      },
    },
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        beginAtZero: false,
        grid: { display: false },
        title: {
          display: true,
          text: 'Acres',
          font: { size: 20 },
        },
        ticks: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: { display: false },
        title: {
          display: true,
          text: `${mapParamsToTitles[(typeParam as keyof typeof mapParamsToTitles) || 'revenue']} Per Acre (${tabToUnit[typeParam as keyof typeof tabToUnit]})`,
          font: { size: 20 },
        },
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value: any) => `${value.toFixed(2)} Acres`,
        color: 'white',
        font: {
          size: 12,
          weight: 'bold',
        },
      },
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || '';
            let value =
              context.raw !== null ? `${context.raw.toFixed(2)} Acres` : 'N/A';
            let crop = fields[selectedField]?.crop || 'Unknown Crop';
            return `${label} (${crop}): ${value}`;
          },
        },
      },
    },
  };

  return (
    <div className="p-0 col-span-3">
      <div className="w-full flex gap-8 justify-between cursor-pointer">
        <CardHeader
          title={typeParam === 'yield_probability' ? `Probability of Hitting Set Yield Goal by Field, ${selectedField}` : `Expected ${mapParamsToTitles[(typeParam as keyof typeof mapParamsToTitles) || 'revenue']} per Acre by Field, ${selectedField}`}
        />
        {/* {selectedMapField && (
          <div
          className="card flex h-[50px] items-center gap-2 px-4 py-2 rounded-md"
          onClick={() => setSelectedMapField('')}
          >
            <ArrowsPointingOutIcon className="w-6 h-6 cursor-pointer" />
            <p className='w-max-w min-w-[100px]'>Show All Fields</p>
          </div>
        )} */}
      </div>

      <div className="h-[400px]">
        <Bar
          data={{ labels: labels ? labels[selectedField] : [], datasets }}
          options={options}
          plugins={[ChartDataLabels]}
        />
      </div>
    </div>
  );
}

export default RevenueByField;
