import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import CardHeader from '../../../components/commons/CardHeader';
import {
  cropColors,
  gradientCropColors,
  mapParamsToTitles,
  tabToUnit,
} from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useControlledUser, useUser } from '../../../contexts/auth-context';
import { useMapContext } from '../../../contexts/map-context';
import { useTableData } from '../../../hooks/map/use-map';
import {
  ArrowsPointingOutIcon,
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/solid';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { MapItem } from '../../../types';
import { capitalizeFirst, formatXBin } from '../../../utils';
import useTypeParams from './useTypeParams';

function RevenuePerAcre() {
  const user = useControlledUser();
  const year = useYearFilter();
  const {
    selectedDate: date,
    setSelectedArea,
    selectedArea: crop,
    setSelectedChartCrop,
  } = useMapContext();

  const typeParam = useTypeParams();
  const type = typeParam === 'hiResolutionSatellite' ? 'revenue' : typeParam;
  const tableData = useTableData(
    user?.id || '',
    crop?.toLowerCase(),
    type!,
    'crop',
    year!,
    date,
    'crop'
  );
  const labels: { [key: string]: string[] } = useMemo(
    () =>
      tableData?.data?.reduce((acc: { [key: string]: string[] }, dataItem: MapItem) => {
        const xBin = dataItem.x_bin === 'nan' ? 'Other' : dataItem.x_bin;
        if (!acc[dataItem.crop]) {
          acc[dataItem.crop] = [];
        }
        if (!acc[dataItem.crop].includes(xBin)) {
          acc[dataItem.crop].push(xBin);
        }
        // Sorting the array for each crop
        acc[dataItem.crop] = acc[dataItem.crop].sort((a, b) => {
          if (a === 'Other') return -1;
          let numA = parseFloat(a.split(' - ')[0].replace(/[^0-9.-]+/g, ''));
          let numB = parseFloat(b.split(' - ')[0].replace(/[^0-9.-]+/g, ''));
          return numA - numB;
        });
        return acc;
      }, {}),
    [tableData?.data]
  );
  

  const crops = useMemo(() => {
    if (!tableData?.data) return {};

    const cropData: { [key: string]: number[] } = {};

    tableData?.data.forEach((dataItem: MapItem) => {
      let area = parseFloat(dataItem.area);
      if (isNaN(area)) area = 0;

      if (!cropData[dataItem.crop]) {
        cropData[dataItem.crop] = Array(labels[crop]?.length ?? 0).fill(0);
      }

      const index = labels[crop]?.indexOf(dataItem.x_bin);
      if (index !== -1) {
        cropData[dataItem.crop][index] = area;
      }
    });

    return cropData;
  }, [tableData?.data, labels]);

  // Get unique crop names from the data
  const uniqueCrops = useMemo(() => Object.keys(crops), [crops]);

  // Pagination states
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 7;
  const [searchTerm, setSearchTerm] = useState<string>('');

  // Filtered crops based on search
  const filteredCrops = useMemo(() => {
    return uniqueCrops.filter((cropName) =>
      cropName?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
  }, [uniqueCrops, searchTerm]);

  // Paginated crops based on current page
  const paginatedCrops = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredCrops.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredCrops, currentPage]);

  useEffect(() => {
    if (paginatedCrops.length > 0 && !crop) {
      setSelectedChartCrop(paginatedCrops[0]);
    }
  }, [paginatedCrops]);

  const chartRef: any = useRef(null);

  const datasets = useMemo(() => {
    if (!crops[capitalizeFirst(crop)]) return [];

    const ctx = chartRef.current?.ctx;
    const chartArea = chartRef.current?.chartArea;

    return [
      {
        label: crop,
        data: crops[capitalizeFirst(crop)],
        backgroundColor: function (context: any) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          const cropColor = cropColors[capitalizeFirst(crop)];
          const gradientCropColor =
            gradientCropColors[capitalizeFirst(crop)];
          if (!chartArea) {
            return cropColor;
          }

          const bar = context.chart.getDatasetMeta(context.datasetIndex).data[
            context.index
          ];
          if (!isFinite(bar.y) || !isFinite(bar.x) || !isFinite(bar.height)) {
            return cropColor;
          }
          const gradient = ctx.createLinearGradient(
            0,
            bar.y,
            0,
            bar.y + bar.height - 10
          ); // Vertical gradient for the current bar
          gradient.addColorStop(0, gradientCropColor);
          gradient.addColorStop(0.5, cropColor);
          return gradient;
        },
        borderWidth: 1,
        borderRadius: 5,
        barThickness: 25,
      },
    ];
  }, [crops, crop]);

  const options: any = {
    layout: {
      padding: {
        left: 0,
        right: 70,
        top: 0,
        bottom: 0,
      },
    },
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        beginAtZero: false,
        grid: { display: false },
        title: {
          display: true,
          text: 'Acres',
          font: { size: 20 },
        },
        ticks: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: { display: false },
        title: {
          display: true,
          text: `${mapParamsToTitles[(typeParam as keyof typeof mapParamsToTitles) || 'revenue']} Per Acre (${tabToUnit[typeParam as keyof typeof tabToUnit]})`,
          font: { size: 20 },
        },
      },
    },
    plugins: {
      legend: { display: false },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value: any) => `${value.toFixed(2)} Acres`,
        color: 'white',
        font: {
          size: 12,
          weight: 'bold',
        },
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || '';
            let value =
              context.raw !== null ? `${context.raw.toFixed(2)} Acres` : 'N/A';
            return `${label}: ${value}`;
          },
        },
      },
    },
  };


  return (
    <div className="p-0 h-full col-span-3">
      <div className="w-full flex gap-8 justify-between cursor-pointer">
        <CardHeader
          title={typeParam === 'yield_probability' ? `Probability of Hitting Set Yield Goal by Crop, ${crop}` : `Expected ${mapParamsToTitles[(typeParam as keyof typeof mapParamsToTitles) || 'revenue']} per Acre by Crop, ${crop}`}
        />
        {/* {selectedChartCrop && (
          <div
            className="card flex h-[50px] items-center gap-2 px-4 py-2 rounded-md"
            onClick={() => {
              setSelectedArea('all');
            }}
          >
            <ArrowsPointingOutIcon className="w-6 h-6 cursor-pointer" />
            <p className="w-max-w min-w-[100px]">Show All Crops</p>
          </div>
        )} */}
      </div>

      <div className="h-[400px]">
        <Bar
          ref={chartRef}
          data={{ labels: labels ? labels[crop] : [], datasets }}
          plugins={[ChartDataLabels]}
          options={options}
        />
      </div>
    </div>
  );
}

export default RevenuePerAcre;
